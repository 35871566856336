import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import FormActions from '../../../../actions/FormActions';
import DatabaseActions from '../../../../actions/DatabaseActions';

function FormModuleSpecies() {
    // Store Interfaces
    const state = useSelector(state => state.form.species);
    const httpState = useSelector(state => state.http);
    const itemState = state.speciesList[state.speciesTabSelected];
    const formName = 'species';
    const dispatch = useDispatch();
    const userName= httpState.session_username;

    // Collects the species from the database currently available to the user
    function collectSpeciesOptions() {
        if(!httpState.speciesDatabaseQueried) {
            if(httpState.sessionLoggedIn){
                refreshSpeciesList(userName);
            }
            else{
                refreshSpeciesList("");
            }
        }
        return httpState.speciesData.map((item, index) =>
        <option key={index.toString()} value={index.toString()} title={item.text}>
            {item.name}
        </option>
        );
    };
    //this function forces a query to the database for all the species to list, which is vital for updating these lists once a species is added, modified, or removed
    function refreshSpeciesList(username){
        dispatch(DatabaseActions.querySecureSpeciesDatabase(username, httpState.sessionLoggedIn));
    };
    // Called number field is unfocused - validates value
    const validateNumberFieldEvent = event => {
        var value = Number(event.target.value);
        if(isNaN(value)) { // If input is not a number set to default value
            value = Number(event.target.default);
        } else if(value < event.target.min) { // If below minimum set to minimum value
            value = event.target.min;
        } else if(value > event.target.max) { // If above maximum set to maximum value
            value = event.target.max;
        };

        // If value was updated dispatch new value
        if(value !== event.target.value) {
            dispatch(FormActions.numberFieldChanged(formName, event.target.name, value));
        };
    };

    // Called when a number field is changed
    const numberFieldChangedEvent = event => {
        dispatch(FormActions.numberFieldChanged(formName, event.target.name, event.target.value));
    };

    // Called when item is enabled by toggling checkbox
    const toggleItemEvent = event => {
        dispatch(FormActions.itemToggled(formName, event.target.name));
    };

    // Called when halving button is clicked
    const halveNumberFieldEvent = event => {
        event.preventDefault();
        dispatch(FormActions.numberFieldHalved(formName, event.target.name));
    };

    // Called when doubling button is clicked
    const doubleNumberFieldEvent = event => {
        event.preventDefault();
        dispatch(FormActions.numberFieldDoubled(formName, event.target.name));
    };

    // Called when option is selected from drop-down list
    const optionSelected = event => {
        dispatch(FormActions.optionSelected(formName, event.target.value));
    };

    // DOM Element Initialization
    var species_options = collectSpeciesOptions();
    var species_select = 
        <select className="select_species" name="speciesSelect" onChange={optionSelected} value={itemState.species_option_selected}>
            {species_options}
        </select>;

    var double_concentration_button = <button className="double_concentration_button" name="concentration" onClick={doubleNumberFieldEvent}>x2</button>
    var half_concentration_button = <button className="half_concentration_button" name="concentration" onClick={halveNumberFieldEvent}>/2</button>
    var speciesName= httpState.speciesData[((itemState.species_option_selected>=httpState.speciesData.length)? 0 : itemState.species_option_selected)].name;
    return(
        <div className="form_module" id="form_module_species">
            <h4>{speciesName}</h4>
            <label>
                Species:
                {species_select}
            </label>
            <label>
                Concentration (uM):
                <input type="number" className="input_concentration" name="concentration" min="0.0" max="1E9" step="any" value={itemState.species_concentration} default="0.5" onChange={numberFieldChangedEvent} onBlur={validateNumberFieldEvent} />
                <div className="concentration_buttons">
                    {double_concentration_button}
                    {half_concentration_button}
                </div>
            </label>
            <label>
                Initial Percent Oxidized (%):
                <input type="number" className="input_percentOxidized" name="percentOxidized" min="0" max="100" step="5" value={itemState.species_percentOxidized} default="0.5" onChange={numberFieldChangedEvent} onBlur={validateNumberFieldEvent} />
            </label>
            <label>
                Include in Simulation:
                <input type="checkbox" className="input_enabled" name="enabled" value={itemState.species_enabled} checked={itemState.species_enabled} onChange={toggleItemEvent} />
            </label>
        </div>
    );
};

export default FormModuleSpecies;