import Urls from './Urls';
import UIActions from './UIActions';
import {types} from './ActionTypes.js';
/* These actions are used for interaction with the species database */

/* Action called when species database query response is received */
const speciesDataCollected = (speciesData) => {
    return {
        type: types.SPECIES_DATA_COLLECTED,
        data: speciesData
    };
};

/* Asynchronous action called when species database is queried */
const speciesDatabaseQueried = () => {
    return {
        type: types.SPECIES_DATABASE_QUERIED,
    };
};

/* Action called when species database query has an error */
const speciesDatabaseError = (errorCode) => {
    return {
        type: types.SPECIES_DATABASE_ERROR,
        error: errorCode
    };
};

/* Action called when species database has been updated */
const speciesDatabaseUpdated = () => {
    return {
        type: types.SPECIES_DATABASE_UPDATED,
    };
};

/* Create an asynchronous action for species database query */
const querySpeciesDatabase = () => {
    return function(dispatch, getState) {
        dispatch(speciesDatabaseQueried());

        // Open a new HTTP request
        var xhr = new XMLHttpRequest();
        xhr.open('GET', Urls.fullUrl, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onreadystatechange = function(e) {
            if(xhr.readyState === XMLHttpRequest.DONE) {
                if(xhr.status === 200) {
                    var speciesList = [];
                    var species_array = JSON.parse(xhr.responseText);
                    for(var i = 0; i < species_array.length; i++) {
                        speciesList.push({
                            name: species_array[i].name,
                            text: species_array[i].text,
                        });
                    };
                    dispatch(speciesDataCollected(speciesList));
                } else {
                    dispatch(speciesDatabaseError(xhr.status));
                };
            };
        };
        xhr.send();
    };
};

/* Create an asynchronous action for signed in species database query */
const querySecureSpeciesDatabase = (username, status) => {
    return function(dispatch, getState) {
        if(status){
            dispatch(speciesDatabaseQueried());
        }
        // Open a new HTTP request
        var loginParams = "type=getSpecies&username=" + username;
        var xhr = new XMLHttpRequest();
        xhr.open('POST', Urls.fullUrl, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onreadystatechange = function(e) {
            if(xhr.readyState === XMLHttpRequest.DONE) {
                if(xhr.status === 200) {
                    var speciesList = [];
                    var species_array = JSON.parse(xhr.responseText);
                    for(var i = 0; i < species_array.length; i++) {
                        speciesList.push({
                            name: species_array[i].name,
                            text: species_array[i].text,
                        });
                    };
                    dispatch(speciesDataCollected(speciesList));
                } else {
                    dispatch(speciesDatabaseError(xhr.status));
                };
            };
        };
        xhr.send(loginParams);
    };
};

/* Action called when user wants to mask/show password */
const showPassword = () => {
    return {
        type: types.SHOW_PASSWORD
    }
}

/* Asynchronous action called when user attempts to log in */
const authenticatingUser = () => {
    return {
        type: types.AUTHENTICATING_USER
    };
};

/* Action called when authentication has an error */
const authenticationError = (errorCode) => {
    return {
        type: types.AUTHENTICATION_ERROR,
        error: errorCode
    };
};

/* Action called when authentication is received and successful */
const authenticationSuccessful = (username) => {
    return {
        type: types.AUTHENTICATION_SUCCESSFUL,
        username: username
    };
};

/* Action called when authentication fails (invalid username/password) */
const authenticationFailed = () => {
    return {
        type: types.AUTHENTICATION_FAILED
    };
};

/* Create an asynchronous action for database user login */
const authenticateUser = (username, password, pageState) => {
    return function(dispatch, getState) {
        dispatch(authenticatingUser());
        // Open a new HTTP request
        var loginParams = "type=validateUser&username=" + username + "&password=" + password;
        var xhr = new XMLHttpRequest();
        xhr.open('POST', Urls.fullUrl, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onreadystatechange = function(e) {
            if(xhr.readyState === XMLHttpRequest.DONE) {
                if(xhr.status === 200) {
                    if(xhr.responseText === "True") {
                        dispatch(authenticationSuccessful(username));
                        dispatch(DatabaseActions.querySecureSpeciesDatabase(username));
                        if(pageState==="login"){
                            dispatch(UIActions.pageStateChanged("modify_species"));
                        }
                    } else {
                        dispatch(authenticationFailed(xhr.responseText));
                    }
                } else {
                    dispatch(authenticationError(xhr.status));
                };
            };
        };
        xhr.send(loginParams);
    };
};

/* Action called when logout happens */
const logoutAction = (username) => {
    return {
        type: types.LOGOUT_USER,
        username: username
    };
};
/* Create an asynchronous action for database user logout */
const logoutUser = (username) => {
    return function(dispatch, getState) {
        // Open a new HTTP request
        var logoutParams = "type=unvalidateUser&username=" + username;
        var xhr = new XMLHttpRequest();
        xhr.open('POST', Urls.fullUrl, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onreadystatechange = function(e) {
            if(xhr.readyState === XMLHttpRequest.DONE) {
                if(xhr.status === 200) {
                    dispatch(logoutAction(username));
                    dispatch(querySecureSpeciesDatabase(''));
                } else {
                    dispatch(authenticationError(xhr.status));
                };
            };
        };
        xhr.send(logoutParams);
    };
};


const DatabaseActions = {
    speciesDataCollected,
    speciesDatabaseQueried,
    speciesDatabaseError,
    speciesDatabaseUpdated,
    querySpeciesDatabase,
    showPassword,
    authenticatingUser,
    authenticationError,
    authenticationSuccessful,
    authenticationFailed,
    authenticateUser,
    logoutUser,
    logoutAction,
    querySecureSpeciesDatabase,
};

export default DatabaseActions;