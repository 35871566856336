import React from 'react';
import './styles.css';
import {useSelector} from 'react-redux';

function DownloadSimulationButton() {
    // Store Interfaces
    const state = useSelector(state => state);
    const waveformState = useSelector(state => state.form.waveform);
    const speciesState = useSelector(state => state.form.species);
    const httpState = useSelector(state => state.http);
    const plotDataState = useSelector(state => state.plotData);

    // Called when the button is clicked
    const buttonClickedEvent = event => {
        event.preventDefault();
        downloadSimulationData();
    };

    // Download simulation data
    function downloadSimulationData() {
        // Collect snapshots of all the inputs/data from simulation
        var cv_plot_data = plotDataState.cvPlotData;
        var cvt_plot_data = plotDataState.cvtPlotData;
        var conc_plot_data = plotDataState.concPlotData;
        var heatmap_plot_data = plotDataState.heatmapPlotData;
        var species_snapshot = speciesState.speciesSnapshot;
        var waveform_snapshot = waveformState.waveformSnapshot;

        var species_data_list = [];
        for(var i = 0; i < species_snapshot.speciesList.length; i++) {
            var species_item = species_snapshot.speciesList[i];
            if(species_item.species_enabled) {
                species_data_list.push({
                    species_name: httpState.speciesData[species_item.species_option_selected].name,
                    species_concentration: species_item.species_concentration,
                    species_percentOxidized: species_item.species_percentOxidized
                });
            };
        };
        var species_data = {
            maximumSpecies: species_snapshot.maximumSpecies,
            speciesList: species_data_list
        };

        var waveform_data = {
            waveform_type: waveformState.waveform_types[waveform_snapshot.waveform_option_selected],
            waveform_minVoltage: waveform_snapshot.waveform_minVoltage,
            waveform_maxVoltage: waveform_snapshot.waveform_maxVoltage,
            waveform_startVoltage: waveform_snapshot.waveform_startVoltage,
            waveform_scanRate: waveform_snapshot.waveform_scanRate,
            waveform_runCycles: waveform_snapshot.waveform_runCycles,
            waveform_reduceData: waveform_snapshot.waveform_reduceData,
            waveform_noisyData: waveform_snapshot.waveform_noisyData
        };

        var save_data = 
            "{\"species_inputs\":"
            + JSON.stringify(species_data)
            + ",\"waveform_inputs\":"
            + JSON.stringify(waveform_data)
            + ",\"plot_data\":{"
            + "\"cv_plot\":"
            + JSON.stringify(cv_plot_data)
            + ",\"cvt_plot\":"
            + JSON.stringify(cvt_plot_data)
            + ",\"conc_plot\":"
            + JSON.stringify(conc_plot_data)
            + ",\"heatmap_plot\":"
            + JSON.stringify(heatmap_plot_data)
            + "}}";

        var filename = 'TestData.json';
        var file = new Blob([save_data], {type: 'text/json'});
        if(window.navigator.msSaveOrOpenBlob) {
            // IE10+
            window.navigator.msSaveOrOpenBlob(file, filename);
        } else {
            var a = document.createElement("a");
            var url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function() {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        };
    };

    return (
        <button className="download_simulation_button" onClick={buttonClickedEvent} disabled={!state.ui.simulationHasRan || httpState.simulationRunning}>
            Download Simulation
        </button>
    );
};

export default DownloadSimulationButton;