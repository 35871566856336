import React from 'react';
import './styles.css';
import {useSelector} from 'react-redux';

function ViewModuleSpecies() {
    // Store Interfaces
    const state = useSelector(state => state);
    const speciesState = useSelector(state => state.form.species);
    const loadedState = speciesState.loadedSpecies;

    // DOM Element Initialization
    var species_data = null;
    if(state.ui.loadedSimulation && !state.ui.experimentalLoaded) {
        //console.log(loadedState);
        var species_item = loadedState.speciesList[loadedState.speciesTabSelected];
        //console.log(species_item);
        species_data = {
            name: species_item.species_name,
            concentration: species_item.species_concentration,
            percentOxidized: species_item.species_percentOxidized
        };
    } else {
        species_data = {
            name: 'No Data',
            concentration: 'N/A',
            percentOxidized: 'N/A'
        };
    }

    return(
        <div className="form_module" id="load_module_species">
            <h4>{species_data.name}</h4>
            <label>
                Species: <span>{species_data.name}</span>
            </label>
            <label>
                Concentration (uM): <span>{species_data.concentration}</span>
            </label>
            <label>
                Initial Percent Oxidized (%):<span>{species_data.percentOxidized}</span>
            </label>
        </div>
    );
};

export default ViewModuleSpecies;