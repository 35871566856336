import React from 'react';
import './styles.css';
import HowToSimulationForm from './form/HowToSimulationForm';

function ControlWindowHowTo() {
    // DOM Element Initialization
    var howto_simulation_form = <HowToSimulationForm />

    return(
        <div className="control_window_view" id="control_window_howto">
            {howto_simulation_form}
        </div>
    );
};

export default ControlWindowHowTo;