import React from 'react';
import ReactDOM from 'react-dom'
import './styles.css';
import Plotly from 'plotly.js/dist/plotly';
import CVPlot from './plot/CVPlot';
import CVTPlot from './plot/CVTPlot';
import ConcPlot from './plot/ConcPlot';
import HeatmapPlot from './plot/HeatmapPlot';
import {useSelector} from 'react-redux';


function PlotContainerGrid() {
    const state = useSelector(state => state);
    const plotState = useSelector(state => state.plotData);

    var cvGraphDiv = null;
    var cvtGraphDiv = null;
    var concGraphDiv = null;
    var heatmapGraphDiv = null;

    function updateCv(fig, graphDiv) { cvGraphDiv = graphDiv; };

    function updateCvt(fig, graphDiv) { cvtGraphDiv = graphDiv; };

    function updateConc(fig, graphDiv) { concGraphDiv = graphDiv; };

    function updateHeatmap(fig, graphDiv) { heatmapGraphDiv = graphDiv; };

    function getGraphDiv(id) {
        switch(id) {
            case 'cv': return cvGraphDiv;
            case 'cvt': return cvtGraphDiv;
            case 'conc': return concGraphDiv;
            case 'heatmap': return heatmapGraphDiv;
            default: return null;
        };
    };
    
    function getVoltageString(index, loaded) {
        var voltage=0;
        try{
            voltage = plotState.cvtPlotData.vt_data.y[index];
            if(voltage===null){
                voltage=0;
            }
            return 'Voltage (mV): ' + (voltage >= 0 ? ' ' : '') + voltage.toFixed(2);

        }
        catch(err){
            try{
                voltage = plotState.cvtPlotDataLoaded.vt_data.y[index];
            }
            catch(err){
                voltage=0;
            }
            if(voltage===null){
                voltage=0;
            }
            return 'Voltage (mV): ' + (voltage >= 0 ? ' ' : '') + voltage.toFixed(2); 
        }
    };

    const timeSliderChangeEvent = (event, src, activeType) => {
        var index = event.slider.active;
        if(src === "conc_plot") {
            if(state.ui.simulationHasRan|| plotState.compare_data){
                var y1=null;
                var y2=null;
                var y3=null;
                var y4=null;
                
                var stepSim= 1e-5;
                var stepLoad= 1e-5;
                var simLength= plotState.cvtPlotDataFull.vt_data.x.length;
                var loadLength= plotState.cvtPlotDataLoaded.vt_data.x.length;
                var endtimeSim= (plotState.cvtPlotDataFull.vt_data.x[simLength-1]);
                var endtimeLoad=(plotState.cvtPlotDataLoaded.vt_data.x[loadLength-1]);
                var longest= simLength;
                var biggestArray=simLength;
                var stepScale= 1;

                if(endtimeLoad>endtimeSim && !state.ui.experimentalLoaded){
                    longest=loadLength;
                }
                if(loadLength>simLength){
                    biggestArray=loadLength;
                }
                //console.log("Simlength: "+simLength + " Loadlength: "+loadLength+ " Biggest array: "+biggestArray+" Longest: "+longest);
               
                if(simLength>2){
                    stepSim= plotState.cvtPlotDataFull.vt_data.x[2]-plotState.cvtPlotDataFull.vt_data.x[1];
                    //console.log("SIMLONG" + stepSim);
                }
                
                if(loadLength>2){
                    stepLoad= plotState.cvtPlotDataLoaded.vt_data.x[2]-plotState.cvtPlotDataLoaded.vt_data.x[1];
                    //console.log("LOADLONG" + stepLoad);

                }

                if(stepSim<=stepLoad){
                    stepScale=stepLoad/stepSim;
                }
                else{
                    stepScale= stepSim/stepLoad;
                }
                if(simLength<=3){
                    stepScale=1;
                }
                if(loadLength<=3){
                    stepScale=1;
                }
                //console.log(stepScale);
                //console.log(plotState.concPlotDataFull.conc_frames.length);
                if(index<plotState.concPlotDataFull.conc_frames.length){
                    y1= plotState.concPlotDataFull.conc_frames[index].data[0].y;
                    y2= plotState.concPlotDataFull.conc_frames[index].data[1].y;                
                }
                else{
                    y1= plotState.concPlotDataFull.conc_frames[plotState.concPlotDataFull.conc_frames.length-1].data[0].y;
                    y2= plotState.concPlotDataFull.conc_frames[plotState.concPlotDataFull.conc_frames.length-1].data[1].y;                             
                }
                if(index<plotState.concPlotDataLoaded.conc_frames.length){
                    y3= plotState.concPlotDataLoaded.conc_frames[index].data[0].y;
                    y4= plotState.concPlotDataLoaded.conc_frames[index].data[1].y;
                }
                else{
                    y3= plotState.concPlotDataLoaded.conc_frames[plotState.concPlotDataLoaded.conc_frames.length-1].data[0].y;
                    y4= plotState.concPlotDataLoaded.conc_frames[plotState.concPlotDataLoaded.conc_frames.length-1].data[1].y;
                }

                Plotly.animate(getGraphDiv('conc'), {
                    data: [
                        { y: y1 },
                        { y: y2 },
                        { y: y3 },
                        { y: y4 }
                    ], traces: [0, 1, 2, 3], layout: {} 
                }, {
                    transition: { duration: 5, easing: 'cubic-in-out' },
                    frame: { duration: 5, redraw: false }
                });
            
                // Concentration slider updates it's voltage label and cv/cvt tracers
                ReactDOM.render(
                    <label className="voltage_label">{getVoltageString(index,false)}</label>, 
                    document.getElementById("conc_plot_pane"));
                // Make a list of opacities and make the current indexed value visible
                var opacities = [];
                var opacities2= [];
                //console.log(index);
                //console.log(stepScale);
                //console.log(stepSim);
                //console.log(stepLoad);
                //console.log(longest);
                //console.log(simLength);
                //console.log(loadLength);
                var i=0;
                if(longest===simLength && simLength!==loadLength){
                    //console.log("longest=simlength");

                    if(stepSim<stepLoad && stepScale!==1){
                        //console.log("stepSim<stepLoad");
                        for(i = 0; i <= biggestArray+1; i++) {
                            if(i === Math.round(index)) {
                                opacities.push(0.75);
                            }
                            else{
                                opacities.push(0);
                            };
                            if(i === Math.round(index/stepScale)) {
                                opacities2.push(0.75);
                            } 
                            else{
                                opacities2.push(0);
                            };
                        };               
                    }
                    else if(stepSim>stepLoad && stepScale!==1){
                        //console.log("stepSim>stepLoad");

                        for(i = 0; i <= biggestArray+1; i++) {
                            if(i === index) {
                                opacities.push(0.75);
                            }
                            else{
                                opacities.push(0);
                            };
                            if(i === Math.round(index*stepScale)) {
                                opacities2.push(0.75);
                            } 
                            else{
                                opacities2.push(0);
                            };
                        };               
                    }
                    else{
                        //console.log("stepSim=stepLoad");

                        for(i = 0; i <= biggestArray+1; i++) {
                            if(i === index) {
                                opacities.push(0.75);
                            } else{
                                opacities.push(0);
                            };
                        };
                        opacities2=opacities;
                    }
                }
                else{
                    //console.log("longest!=simLength or simLength=loadLength");
                    if(stepSim<stepLoad && stepScale!==1){
                      //  console.log("stepSim<stepLoad");

                        for(i = 0; i <= biggestArray+1; i++) {
                            if(i === Math.round(index*stepScale)) {
                                opacities.push(0.75);
                            }
                            else{
                                opacities.push(0);
                            };
                            if(i === Math.round(index)) {
                                opacities2.push(0.75);
                            } 
                            else{
                                opacities2.push(0);
                            };
                        };               
                    }
                    else if(stepSim>stepLoad && stepScale!==1){
                        //console.log("stepSim>stepLoad");

                        for(i = 0; i <= biggestArray+1; i++) {
                            if(i === Math.round(index*stepScale)) {
                                opacities2.push(0.75);
                            }
                            else{
                                opacities2.push(0);
                            };
                            if(i === Math.round(index)) {
                                opacities.push(0.75);
                            } 
                            else{
                                opacities.push(0);
                            };
                        };               
                    }
                    else{
                        //console.log("stepSim=stepLoad");

                        for(i = 0; i <= biggestArray+1; i++) {
                            if(i === index) {
                                opacities.push(0.75);
                            } else{
                                opacities.push(0);
                            };
                        };
                        opacities2=opacities;
                    }
                }
                
                // Update the CV graph to show these markers
                if(!state.ui.isDPVAndRan){
                    Plotly.restyle(getGraphDiv('cv'),{marker: [{opacity: opacities}, {opacity: opacities2}]},[0,1])
                }

                // Update the CVT graph to show these markers
                Plotly.restyle(getGraphDiv('cvt'),{marker: [{opacity: opacities}, {opacity: opacities}, {opacity: opacities2}, {opacity: opacities2}]},[0,1,2,3])


            }

        } else if(src === "heatmap_plot") {
            var z1=null;
            if(state.ui.simulationHasRan && activeType==="sim"){
                // Heatmap currently only updates it's voltage label
                ReactDOM.render(
                    <label className="voltage_label">{getVoltageString(index,false)}</label>, 
                    document.getElementById("heatmap_plot_pane"));
                    if(index<plotState.heatmapPlotData.heatmap_frames.length){
                        z1= plotState.heatmapPlotData.heatmap_frames[index].data[0].z;
                    }
                    else{
                        z1= plotState.heatmapPlotData.heatmap_frames[plotState.heatmapPlotData.heatmap_frames.length-1].data[0].z;
                    }
                Plotly.restyle(getGraphDiv('heatmap'), 
                    { z: [z1], traces:[0]});
            }
            if(plotState.compare_data && activeType==="load"){
                // Heatmap currently only updates it's voltage label
                ReactDOM.render(
                    <label className="voltage_label">{getVoltageString(index,true)}</label>, 
                    document.getElementById("heatmap_plot_pane"));
                    if(index<plotState.heatmapPlotDataLoaded.heatmap_frames.length){
                        z1= plotState.heatmapPlotDataLoaded.heatmap_frames[index].data[0].z;
                    }
                    else{
                        z1= plotState.heatmapPlotDataLoaded.heatmap_frames[plotState.heatmapPlotDataLoaded.heatmap_frames.length-1].data[0].z;
                    }
                Plotly.restyle(getGraphDiv('heatmap'), 
                    { z: [z1], traces:[1] });
            }

        };
    };

    var cvPlot= <CVPlot update={updateCv} getGraphDiv={getGraphDiv} />;
    // Attempt to match a waveform name against the list of types
    if(state.ui.isDPVAndRan){
        cvPlot=null;
    }
    return(
        <div className="plot_container_grid">
            {cvPlot}
            <CVTPlot update={updateCvt} getGraphDiv={getGraphDiv} />
            <ConcPlot update={updateConc} getGraphDiv={getGraphDiv} sliderMoved={timeSliderChangeEvent} />
            <HeatmapPlot update={updateHeatmap} getGraphDiv={getGraphDiv} sliderMoved={timeSliderChangeEvent} />
        </div>
    );
};

export default PlotContainerGrid;