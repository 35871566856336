import React from 'react';
import './SpeciesModuleTab.css';

function SpeciesModuleTab(props) {
    return(
        <React.Fragment>
            <input type="radio"
                className="species_tab"
                name="species_tabs"
                value={props.tabValue}
                id={props.tabName}
                checked={props.tabValue === props.speciesSelected} 
                onChange={props.onSpeciesChanged} />
            <label htmlFor={props.tabName}>{props.tabLabel}</label>
        </React.Fragment>
    );
};

export default SpeciesModuleTab;