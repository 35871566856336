import Urls from './Urls';
import UIActions from './UIActions';
import PlotActions from './PlotActions';
import {types} from './ActionTypes.js';
import zlib from 'react-zlib-js';

/* These actions are used for server simulation */

/* Action called when species database query response is received */
const simulationDataReceived = (simulationData) => {
    return {
        type: types.SIMULATION_DATA_RECEIVED,
        data: simulationData
    };
};

/* Asynchronous action called when species database is queried */
const simulationRunning = () => {
    return {
        type: types.SIMULATION_RUNNING
    };
};

/* Action called when simulation has an error */
const simulationError = (errorCode) => {
    return {
        type: types.SIMULATION_ERROR,
        error: errorCode
    };
};

/* Action called to save waveform inputs after simulation */
const saveWaveformData = () => {
    return {
        type: types.SAVE_WAVEFORM_DATA,
        form: 'waveform'
    };
};

/* Action called to save species inputs after simulation */
const saveSpeciesData = () => {
    return {
        type: types.SAVE_SPECIES_DATA,
        form: 'species'
    };
};

/* Action called when waveform inputs are loaded */
const waveformDataLoaded = (waveform_loaded) => {
    return {
        type: types.WAVEFORM_DATA_LOADED,
        form: 'waveform',
        loaded: waveform_loaded
    };
};

/* Action called when species inputs are loaded */
const speciesDataLoaded = (species_loaded) => {
    return {
        type: types.SPECIES_DATA_LOADED,
        form: 'species',
        loaded: species_loaded
    };
};

/* Action called when cv plot data is loaded */
const cvPlotDataLoaded = (cv_plot_loaded) => {
    return {
        type: types.CV_PLOT_DATA_LOADED,
        loaded: cv_plot_loaded
    };
};

/* Action called when cvt plot data is loaded */
const cvtPlotDataLoaded = (cvt_plot_loaded) => {
    return {
        type: types.CVT_PLOT_DATA_LOADED,
        loaded: cvt_plot_loaded
    };
};

/* Action called when conc plot data is loaded */
const concPlotDataLoaded = (conc_plot_loaded) => {
    return {
        type: types.CONC_PLOT_DATA_LOADED,
        loaded: conc_plot_loaded
    };
};

/* Action called when heatmap plot data is loaded */
const heatmapPlotDataLoaded = (heatmap_plot_loaded) => {
    return {
        type: types.HEATMAP_PLOT_DATA_LOADED,
        loaded: heatmap_plot_loaded
    };
};

/* Action called when all the data has been loaded, to show on plots*/
const showDataLoaded = () => {
    return {
        type: types.SHOW_DATA_LOADED,
    };
};

/* Action called when editing species data */
const editSpeciesData = (speciesData) => {
    return {
        type: types.EDIT_DATA,
        form: 'species',
        data: speciesData
    };
};

/* Action called when editing waveform data */
const editWaveformData = (waveformData) => {
    return {
        type: types.EDIT_DATA,
        form: 'waveform',
        data: waveformData
    };
};

/* Action called when experimental data loaded */
const experimentalDataLoaded = (experiment_loaded) => {
    return {
        type: types.EXPERIMENT_LOADED,
        data: experiment_loaded
    };
};

/* Create an asynchronous action for species database query */
const runSimulation = (simulationPostParameters, numCycles, waveType) => {
    return function(dispatch, getState) {
        dispatch(simulationRunning());

        // Open a new HTTP request
        var xhr = new XMLHttpRequest();
        xhr.open('POST', Urls.fullUrl, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onreadystatechange = function(e) {
            if(xhr.readyState === XMLHttpRequest.DONE) {
                if(xhr.status === 200) {
                    var buffer = Buffer.from(xhr.responseText, 'base64');
                    zlib.inflate(buffer, (err, str) => {
                        if(!err) {
                            //Check for matlab warning, removes first line if the data does not starts with "{"
                            //console.log(str.toString());
                            if(!str.toString().startsWith("{") ){
                                var data = JSON.parse(str.toString().substring(str.toString().indexOf("\n")+1));
                            }
                            else { 
                                var data = JSON.parse(str.toString());
                            }
                            if(data.hasOwnProperty('error')) {
                                console.error("A Matlab error was caught:\n" + data.error);
                                dispatch(simulationError("Matlab Exception Occurred"));
                            } else {
                                dispatch(simulationDataReceived(data));
                                dispatch(PlotActions.parsePlotData(data, numCycles));
                                dispatch(saveWaveformData());
                                dispatch(saveSpeciesData());
                                dispatch(UIActions.simulationRan(numCycles, waveType));
                            }
                        } else {
                            dispatch(simulationError("Data Encoding Error Occured"));
                        }
                    });
                } else {
                    dispatch(simulationError("Connection Error Occured"));
                };
            };
        };
        xhr.send(simulationPostParameters);
    };
};

const SimulationActions = {
    simulationDataReceived,
    simulationRunning,
    simulationError,
    saveWaveformData,
    saveSpeciesData,
    runSimulation,
    waveformDataLoaded,
    speciesDataLoaded,
    cvPlotDataLoaded,
    cvtPlotDataLoaded,
    concPlotDataLoaded,
    heatmapPlotDataLoaded,
    editSpeciesData,
    editWaveformData,
    showDataLoaded,
    experimentalDataLoaded,
};

export default SimulationActions;
