import React from 'react';
import './ControlWindowModeTab.css';

function ControlWindowModeTab(props) {
    return(
        <React.Fragment>
            <input type="radio"
                className="mode_tab"
                name="mode_tabs"
                value={props.tabValue}
                id={props.tabName}
                checked={props.tabValue === props.modeSelected} 
                onChange={props.onModeChanged} />
            <label className="mode_tab_label" htmlFor={props.tabName}>{props.tabLabel}</label>
        </React.Fragment>
    );
};

export default ControlWindowModeTab;