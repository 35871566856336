import {types} from '../actions/ActionTypes.js';

/* The default state for a new species added to the list */
const defaultSpeciesState = {
    species_option_selected: 0,
    species_concentration: 0.5,
    species_percentOxidized: 0,
    species_enabled: true
};

/* The default state for the species form module */
const defaultState = {
    maximumSpecies: 5,
    speciesTabSelected: 0,
    speciesList: [defaultSpeciesState],
    speciesSnapshot: null,
    loadedSpecies: 0
};

/* The reducer for the species form module */
const SpeciesReducer = (state = defaultState, action) => {
    if(action.form === 'species') {
        var stateName = "species_";
        var newSpeciesList = state.speciesList;
        switch(action.type) {
            case types.LIST_ITEM_ADDED:
                newSpeciesList.push(defaultSpeciesState);
                var newSpeciesTabSelected = state.speciesList.length - 1;
                return {...state, speciesList: newSpeciesList, speciesTabSelected: newSpeciesTabSelected};
            case types.TAB_SELECTED:
                var selectedTab= action.selected;
                return {...state, speciesTabSelected: selectedTab};
            case types.OPTION_SELECTED:
                newSpeciesList[state.speciesTabSelected] = {
                    ...state.speciesList[state.speciesTabSelected],
                    species_option_selected: action.selected
                };
                return {...state, speciesList: newSpeciesList};
            case types.ITEM_TOGGLED:
                var enabled = newSpeciesList[state.speciesTabSelected].species_enabled;
                newSpeciesList[state.speciesTabSelected] = {
                    ...state.speciesList[state.speciesTabSelected],
                    species_enabled: !enabled
                };
                return {...state, speciesList: newSpeciesList};
            case types.TEXT_FIELD_CHANGED:
                stateName += action.field;
                newSpeciesList[state.speciesTabSelected] = {
                    ...state.speciesList[state.speciesTabSelected],
                    [stateName]: action.text
                };
                return {...state, speciesList: newSpeciesList};
            case types.NUMBER_FIELD_CHANGED:
                stateName += action.field;
                newSpeciesList[state.speciesTabSelected] = {
                    ...state.speciesList[state.speciesTabSelected],
                    [stateName]: action.value
                };
                return {...state, speciesList: newSpeciesList};
            case types.NUMBER_FIELD_HALVED:
                stateName += action.field;
                var halvedValue = newSpeciesList[state.speciesTabSelected][stateName] / 2.0;
                newSpeciesList[state.speciesTabSelected] = {
                    ...state.speciesList[state.speciesTabSelected],
                    [stateName]: halvedValue
                };
                return {...state, speciesList: newSpeciesList};
            case types.NUMBER_FIELD_DOUBLED:
                stateName += action.field;
                var doubledValue = newSpeciesList[state.speciesTabSelected][stateName] * 2.0;
                newSpeciesList[state.speciesTabSelected] = {
                    ...state.speciesList[state.speciesTabSelected],
                    [stateName]: doubledValue
                };
                return {...state, speciesList: newSpeciesList};
            case types.SAVE_SPECIES_DATA:
                var maximumSpecies = state.maximumSpecies;
                var speciesList = state.speciesList;
                
                var newSnapshot = {
                    maximumSpecies,
                    speciesList
                };
                return {...state, speciesSnapshot: newSnapshot};
            case types.SPECIES_DATA_LOADED:
                var loadedSpecies = action.loaded;
                loadedSpecies.speciesTabSelected = 0;
                if (typeof loadedSpecies.maximumSpecies == 'undefined' || typeof loadedSpecies.maximumSpecies != 'number') {
                    loadedSpecies.maximumSpecies= "Undefined";
                }
                if (typeof loadedSpecies.speciesList == 'undefined' || typeof loadedSpecies.speciesList != 'object') {
                    loadedSpecies.speciesList= [];
                    var undefinedSpecies={
                        species_concentration: "Undefined",
                        species_name: "Undefined",
                        species_percentOxidized: "Undefined",
                    };
                    loadedSpecies.speciesList.push(undefinedSpecies);
                }
                for (let i = 0; i < loadedSpecies.speciesList.length; i++) {
                    if (typeof loadedSpecies.speciesList[i].species_concentration == 'undefined'  || typeof loadedSpecies.speciesList[i].species_concentration != 'number') {
                            loadedSpecies.speciesList[i].species_concentration= "Undefined";
                    }       
                    if (typeof loadedSpecies.speciesList[i].species_name == 'undefined' || typeof loadedSpecies.speciesList[i].species_name != 'string') {
                        loadedSpecies.speciesList[i].species_name= "Undefined";
                    }      
                    if (typeof loadedSpecies.speciesList[i].species_percentOxidized == 'undefined' || typeof loadedSpecies.speciesList[i].species_percentOxidized != 'number') {
                        loadedSpecies.speciesList[i].species_percentOxidized= "Undefined";
                    }      
                }
                return {...state, loadedSpecies}
            case types.EDIT_DATA:
                var speciesData = action.data;
                if (speciesData.maximumSpecies === "Undefined") {
                    speciesData.maximumSpecies = defaultState.maximumSpecies;
                }       
                for (let i = 0; i < speciesData.speciesList.length; i++) {
                    if (speciesData.speciesList[i].species_concentration === "Undefined") {
                            speciesData.speciesList[i].species_concentration= defaultSpeciesState.species_concentration;
                    }       
                    if (speciesData.speciesList[i].species_percentOxidized === "Undefined") {
                        speciesData.speciesList[i].species_percentOxidized= defaultSpeciesState.species_percentOxidized;
                    }      
                }
                return {...state, 
                    maximumSpecies: speciesData.maximumSpecies,
                    speciesTabSelected: speciesData.speciesTabSelected,
                    speciesList: speciesData.speciesList
                };
            default:
                return state;
        };
    } else if(action.form === "species_load") {
        switch(action.type) {
            case types.TAB_SELECTED:
                return {...state, loadedSpecies: {...state.loadedSpecies,
                    speciesTabSelected: action.selected
                }};
            default:
                return state;
        };
    } 
    else {
        return state;
    };
};

export default SpeciesReducer;