import React from 'react';
import './styles.css';
import CreateSimulationForm from './form/CreateSimulationForm';

function ControlWindowCreate() {
    // DOM Element Initialization
    var create_simulation_form = <CreateSimulationForm />

    return(
        <div className="control_window_view" id="control_window_create">
            {create_simulation_form}
        </div>
    );
};

export default ControlWindowCreate;