import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import UIActions from '../../../../actions/UIActions';

function ModifySpeciesButton() {
    // Store Interfaces
    const state = useSelector(state => state);
    const httpState = useSelector(state => state.http);
    const dispatch = useDispatch();

    // Called when the button is clicked - access database panel
    function accessSpeciesDatabase() {
        if(httpState.sessionLoggedIn) {
            dispatch(UIActions.pageStateChanged("modify_species"));
        } else {
            dispatch(UIActions.pageStateChanged("login"));
        }
    }

    return (
        <button className="modify_species_button" onClick={accessSpeciesDatabase} disabled={!(state.ui.pageState === 'view_simulation') || httpState.simulationRunning}>
            Modify Species List
        </button>
    );
};

export default ModifySpeciesButton;