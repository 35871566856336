import React from 'react';
import './styles.css';
import {useSelector} from 'react-redux';

function ViewModuleSignal() {
    // Store Interfaces
    const state = useSelector(state => state);
    const waveformState = useSelector(state => state.form.waveform);
    const loadedState = waveformState.loadedWaveform;

    // DOM Element Initialization
    var signal_data = null;
    if(state.ui.loadedSimulation && !state.ui.experimentalLoaded) {
        signal_data = {
            type: loadedState.waveform_type,
            minVoltage: loadedState.waveform_minVoltage,
            maxVoltage: loadedState.waveform_maxVoltage,
            startVoltage: loadedState.waveform_startVoltage,
            scanRate: loadedState.waveform_scanRate,
            runCycles: loadedState.waveform_runCycles,
            reduceData: loadedState.waveform_reduceData.toString(),
            noisyData: loadedState.waveform_noisyData.toString(),
        };
    } else {
        signal_data = {
            type: 'No Data',
            minVoltage: 'N/A',
            maxVoltage: 'N/A',
            startVoltage: 'N/A',
            scanRate: 'N/A',
            runCycles: 'N/A',
            reduceData: 'N/A',
            noisyData: 'N/A',
        };
    }

    return(
        <div className="form_module" id="load_module_signal">
            <h4>Wave Form</h4>
            <label>
                Signal Type: <span>{signal_data.type}</span>
            </label>
            <label>
                Min Voltage (V): <span>{signal_data.minVoltage}</span>
            </label>
            <label>
                Max Voltage (V): <span>{signal_data.maxVoltage}</span>
            </label>
            <label>
                Starting Voltage (V): <span>{signal_data.startVoltage}</span>
            </label>
            <label>
                Scan Rate (mV/s): <span>{signal_data.scanRate}</span>
            </label>
            <label>
                Cycles Simulated: <span>{signal_data.runCycles}</span>
            </label>
            <label>
                Was the data Reduced: <span>{signal_data.reduceData}</span>
            </label>
            <label>
                Was the data noisy: <span>{signal_data.noisyData}</span>
            </label>
        </div>
    );
}

export default ViewModuleSignal;