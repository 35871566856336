import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import FormActions from '../../../../actions/FormActions';
import SpeciesModuleTab from './input/SpeciesModuleTab';
import FormModuleSpecies from './FormModuleSpecies';
import FormModuleSignal from './FormModuleSignal';
import SimulateButton from './input/SimulateButton';
import DownloadSimulationButton from '../input/DownloadSimulationButton';
import ModifySpeciesButton from '../input/ModifySpeciesButton';

function CreateSimulationForm() {
    // Store Interfaces
    const state = useSelector(state => state.form);
    const dataState = useSelector(state => state.http);
    const dispatch = useDispatch();

    // Called whenever a species tab is selected
    const speciesChangedEvent = event => {
        dispatch(FormActions.tabSelected('species', event.target.name, Number(event.target.value)));
    };

    // Called whenever a new species tab is added
    const speciesAddedEvent = event => {
        if(state.species.speciesList.length < state.species.maximumSpecies) {
            dispatch(FormActions.listItemAdded('species'));
            dispatch(FormActions.tabSelected('species', dataState.speciesData[0].name, Number(state.species.speciesList.length)-1));
            species_tabs=species_tabs;
        };
    };
    //console.log(speciesDataLength);
    // DOM Element Initialization
    var species_tabs = state.species.speciesList.map((item, index) =>
        <SpeciesModuleTab key={index.toString()}
            
            tabName={"tab_species_" + String(index + 1)} 
            tabValue={index}
            tabLabel={dataState.speciesData[((item.species_option_selected>=dataState.speciesData.length)? 0 : item.species_option_selected)].name}  
            speciesSelected={state.species.speciesTabSelected}
            onSpeciesChanged={speciesChangedEvent} />
    );
    //react fragment for the button to add an adittional species to the simulation instance
    var species_tab_add = 
        <React.Fragment>
            <input type="button" id="add_species_tab" onClick={speciesAddedEvent} />
            <label htmlFor="add_species_tab">+</label>
        </React.Fragment>;

    return(
        <form className="create_simulation_form">
            <div className="species_tab_bar">
                {species_tabs}
                {species_tab_add}
            </div>
            <FormModuleSpecies />
            <FormModuleSignal />
            <SimulateButton />
            <div className="misc_button_bar">
                <DownloadSimulationButton />
                <ModifySpeciesButton />
            </div>
        </form>
    );
};

export default CreateSimulationForm;