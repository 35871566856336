import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import UIActions from '../../actions/UIActions';
import ControlWindowModeTab from './ui/input/ControlWindowModeTab';
import ControlWindowCreate from './ui/ControlWindowCreate';
import ControlWindowLoad from './ui/ControlWindowLoad';
import ControlWindowHowTo from './ui/ControlWindowHowTo';

function ControlWindowPanel() {
    // Store Interfaces
    const state = useSelector(state => state.ui);
    const dispatch = useDispatch();

    // Called whenever a mode tab is selected
    const modeChangedEvent = event => {
        dispatch(UIActions.controlPanelModeSelected(event.target.value));
    };

    // DOM Element Initialization
    var control_window_view = null;
    switch(state.controlPanelMode) {
        case 'load':
            control_window_view = <ControlWindowLoad />
            break;
        case 'create':
            control_window_view = <ControlWindowCreate />
            break;
        case 'howto':
            control_window_view = <ControlWindowHowTo />    
            break;
        default:
            control_window_view = <ControlWindowCreate />
            break;
    };

    return(
        <div className="control_window_panel">
            <div className="mode_tab_bar">
            {/* Contains tabs that select the control panel mode */}
                <ControlWindowModeTab tabName="tab_create" tabValue="create" tabLabel="Create" modeSelected={state.controlPanelMode} onModeChanged={modeChangedEvent} />
                <ControlWindowModeTab tabName="tab_load" tabValue="load" tabLabel="Load" modeSelected={state.controlPanelMode} onModeChanged={modeChangedEvent} />
                <ControlWindowModeTab tabName="tab_howto" tabValue="howto" tabLabel="About/ Tutorial" modeSelected={state.controlPanelMode} onModeChanged={modeChangedEvent} />

            </div>
            {control_window_view}
            {/* The control panel view to display (based on selected mode) */}
        </div>
    );
};

export default ControlWindowPanel;