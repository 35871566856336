import React from 'react';
import './index.css';
import PanelContainer from './components/layout/PanelContainer';
import LoginBar from './components/layout/LoginBar';

function App() {
    return(
        <React.Fragment>
            <LoginBar className="login">
            </LoginBar>
            <PanelContainer>
            {/* Parent container for page body */}
            </PanelContainer>
            <div className="footer">
                <p>This work was supported by the US National Science Foundation (NSF) through grant ECCS-1807604 and grant CCF-1816969, and the Semiconductor Research Corporation (SRC) through task 2843.001.</p>
                <div className="logo_bar">
                    <img src={process.env.PUBLIC_URL + '/nsf_logo.png'} alt="US National Science Foundation" className="logo" id="nsf_logo" />
                    <img src={process.env.PUBLIC_URL + '/src_logo.png'} alt="Semiconductor Research Corporation" className="logo" id="src_logo" />
                </div>
                <p><strong>Disclaimer:</strong> Any opinions, findings, and conclusions or recommendations expressed in this material are those of the author(s) and do not necessarily reflect the views of the National Science Foundation</p>
            </div>
        </React.Fragment>
    );
};

export default App;