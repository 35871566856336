import React from 'react';
import Plot from 'react-plotly.js';
import './styles.css';
import {useSelector} from 'react-redux';

function HeatmapPlot(props) {
    // Store Interfaces
    const state = useSelector(state => state.plotData);
    const UIstate = useSelector(state => state.ui);

    // Default layout properties
    var config = state.default_config;
    var layout = state.default_layout;
    var slider = state.default_slider_format.time_slider;
    var slider_loaded = state.default_slider_format.time_slider;
    var visiblePlot="none";
    var graphDiv= "concentration_heatmap";
    var simVisible=UIstate.simulationHasRan;
    var loadVisible=state.compare_data;
    var selectVisible=false;
    var savedSlider=0;
    var hasSliderChanged=false;
    var visibleStateTemp=visiblePlot;
    var simLength= state.cvtPlotDataFull.vt_data.x.length;
    var loadLength= state.cvtPlotDataLoaded.vt_data.x.length;
    var endtimeSim= (state.cvtPlotDataFull.vt_data.x[simLength-1]);
    var endtimeLoad=(state.cvtPlotDataLoaded.vt_data.x[loadLength-1]);
    if(UIstate.experimentalLoaded){
        loadVisible=false;
    }



    var dist_arr_x = state.heatmapPlotData.heatmap_x;
    var dist_arr_y = state.heatmapPlotData.heatmap_y;
    var frames = state.heatmapPlotData.heatmap_frames;
    var dist_arr_x_loaded = state.heatmapPlotDataLoaded.heatmap_x;
    var dist_arr_y_loaded = state.heatmapPlotDataLoaded.heatmap_y;
    var frames_loaded = state.heatmapPlotDataLoaded.heatmap_frames;
    
    var trueframes=frames;
    
    if(UIstate.simulationHasRan && state.compare_data){
        selectVisible=true;
        simVisible=true;
        loadVisible=false;
        //visiblePlot="sim";
        
    }
    if(UIstate.experimentalLoaded){
        selectVisible=false;
        simVisible=true;
        loadVisible=false;
        visiblePlot="sim";
    }

    var simTrace={ 
        visible: simVisible,
        name: 'data',
        x: dist_arr_x,
        y: dist_arr_y,
        z: frames[0].data[0].z,
        zauto: false,
        zmin: 0,
        zmax: 1,
        type: 'heatmap',
        colorscale: 'Viridis',
        colorbar:{
            title:{
                text:'Portion Oxidized',
                side: 'right',
                font:{color: '#0000FF',},
                size: 12,  
            },
        }

    };
    var loadTrace={
        visible: loadVisible,
        name: 'loaded_data',
        x: dist_arr_x_loaded,
        y: dist_arr_y_loaded,
        z: frames_loaded[0].data[0].z,
        zauto: false,
        zmin: 0,
        zmax: 1,
        type: 'heatmap',
        colorscale: 'YlGnBu',
        colorbar:{
            title:{
                text:'Portion Oxidized Loaded',
                side: 'right',
                font:{color: '#0000FF',},
                size: 12,                                            
            },
        }
    };
   
    var updatemenus= [
        {
            type: "buttons",
            visible: selectVisible,
            active: 0,
            buttons: [
                {
                    args: [{'visible': [true, false]}],
                    label: 'Simulated',
                    method:'restyle',
                },
                {
                    args: [{'visible': [false, true]}],
                    label:'Loaded',
                    method:'restyle'
                },
            ],
            direction: 'left',
            pad: {'r': 10, 't': 10},
            showactive: true,
            x: -0.1,
            xanchor: 'left',
            y: 1.35,
            yanchor: 'top'
        },
    ];
    var new_layout = { ...layout,
        title: { ...layout.title, text: 'Concentration Heatmap' },
        xaxis: { ...layout.xaxis, title: { ...layout.xaxis.title, text: 'Distance from Electrode (cm)' } },
        yaxis: { ...layout.yaxis, nticks: 2, visible: false },
        sliders: [{ ...slider, steps: state.heatmapPlotData.heatmap_steps, name: "heatmap_slider"},{ ...slider_loaded, steps: state.heatmapPlotDataLoaded.heatmap_steps, name: "heatmap_slider_loaded"}],
        updatemenus:updatemenus,
        automargin: true,
    };
    new_layout.sliders[0].visible=UIstate.simulationHasRan;
    new_layout.sliders[1].visible=state.compare_data;
    if(new_layout.sliders[0].visible && new_layout.sliders[1].visible){
        if(endtimeSim>= endtimeLoad || UIstate.experimentalLoaded){
            new_layout.sliders[0].visible=true;
            new_layout.sliders[1].visible=false;
        }
        else{
            new_layout.sliders[1].visible=true;
            new_layout.sliders[0].visible=false;
        }

        //new_layout.sliders[0].visible=false;
    }
    if(UIstate.experimentalLoaded){
        new_layout.sliders[0].visible=true;
        new_layout.sliders[1].visible=false;
        if(!UIstate.simulationHasRan){
            new_layout.sliders[0].visible=false;

        }
    }
    function updateVisible(e){
        if(simTrace.visible){
            visiblePlot="sim";
        }
        if(loadTrace.visible){
            visiblePlot="load";
        }
        if(visiblePlot==="sim"){
            trueframes=frames;
    
        }
        else if(visiblePlot==="load"){
            trueframes=frames_loaded;
        }
        if(visiblePlot!== visibleStateTemp && hasSliderChanged){
            props.sliderMoved(savedSlider, "heatmap_plot", visiblePlot);
            visibleStateTemp=visiblePlot;        
        }


    }


    if(simTrace.visible){
        visiblePlot="sim";
    }
    if(loadTrace.visible){
        visiblePlot="load";

    }
    if(visiblePlot==="sim"){
        trueframes=frames;
    }
    else if(visiblePlot==="load"){
        trueframes=frames_loaded;
    }
    const sliderChangeEvent = (e) => {
        savedSlider= e;
        props.sliderMoved(e, "heatmap_plot", visiblePlot);
        hasSliderChanged=true;
    }
    return(
        <div className="plot_container_pane">
            <Plot className="plot"
                id="heatmap_plot"
                name="heatmap_plot"
                graphDiv={graphDiv}
                data = {[simTrace, loadTrace]}
                frames = {trueframes}
                layout = {new_layout}
                config = {config}
                onUpdate={props.update}
                onRestyle={(e) => updateVisible(e)}
                onSliderChange={(e) => sliderChangeEvent(e)}

            />
            <div id="heatmap_plot_pane"><label className="voltage_label">Voltage (mV): </label></div>
        </div>
    );
};

export default HeatmapPlot;