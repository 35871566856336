import {types} from '../actions/ActionTypes.js';

/* The default state for the application */
const defaultState = {
    controlPanelMode: 'create',
    simulationHasRan: false,
    pageState: 'view_simulation',
    loadedSimulation: false,
    experimentalLoaded: false,
    lastFileValue: null,
    lastFileModified: 0,
    invalidFile: false,
    savedNumCycles: 1,
    isDPVAndRan: false
};

const UIReducer = (state = defaultState, action) => {

    switch(action.type) {
        case types.CONTROL_PANEL_MODE_CHANGED:
            return {...state, controlPanelMode: action.mode};
        case types.SIMULATION_RAN:
            var waveType=action.waveType;
            var isDPVAndRan=false;
            if(waveType==="dpv"){
                isDPVAndRan=true;
            }
            return {...state, simulationHasRan: true, savedNumCycles: action.numCycles, isDPVAndRan: isDPVAndRan};
        case types.PAGE_STATE_CHANGED:
            return {...state, pageState: action.page};
        case types.SIMULATION_LOADED:
            return {...state, loadedSimulation: true, experimentalLoaded: action.isExperimental, invalidFile: false};
        case types.NEW_FILE_LOADED:
            return {...state, lastFileValue: action.value, lastFileModified: action.lastModified};
        case types.INVALID_INPUT_FILE:
            return {...state, loadedSimulation: false, invalidFile: true}
        default:
            return state;
    };
};

export default UIReducer;