import {types} from '../actions/ActionTypes.js';

/* The default state for the CV Plot */
const defaultCVPlotState = {
    automargin: true,
    cv_data: {
        name: 'I(V)',
        type: 'scattergl',
        x: [0.0, 0.0], y: [0.0, 0.0],
        mode: 'lines+markers',
        marker: {
            size: 7,
            opacity: 0
        },
        line: {
            color: '#FF2200',
            width: 1
        }
    },

};

/* The default state for the Voltage/Current vs Time Plot */
const defaultCVTPlotState = {
    automargin: true,
    frequency: 0,
    vt_data: {
        name: 'V(t)',
        type: 'scattergl',
        x: [0.0, 0.0], y: [0.0, 0.0],
        yaxis: 'y2',
        mode: 'lines+markers',
        marker: {
            size: 7,
            opacity: 0
        },
        line: {
            color: '#002244',
            width: 1
        }
    },
    ct_data: {
        automargin: true,
        name: 'I(t)',
        type: 'scattergl',
        x: [0.0, 0.0], y: [0.0, 0.0],
        mode: 'lines+markers',
        marker: {
            size: 7,
            opacity: 0
        },
        line: {
            color: '#FF2200',
            width: 1
        }
    }
};

/* The default state for the Concentration vs Distance Plot */
const defaultConcPlotState = {
    automargin: true,
    conc_x: [0.0],
    conc_frames: [
        { data: [{y: [0.0]}, {y: [0.0]}] },
        { data: [{y: [0.0]}, {y: [0.0]}] }
    ],
    conc_steps: [
        { label: '0.0', method: 'skip' },
        { label: '0.0', method: 'skip' }
    ],
};

/* The default state for the Heatmap Plot */
const defaultHeatmapPlotState = {
    automargin: true,
    heatmap_x: [0.0, 1.0],
    heatmap_y: [0.0, 1.0],
    heatmap_frames: [
        { data: [{z: [[0.0, 0.0], [0.0, 0.0]]}] },
        { data: [{z: [[0.0, 0.0], [0.0, 0.0]]}] }
    ],
    heatmap_steps: [
        { label: '0.0', method: 'skip' },
        { label: '0.0', method: 'skip' }
    ]
};

/* The default configuration for plots */
const defaultConfigState = {
    responsive: true
}

/* The default layout for plots */
const defaultLayoutState = {
    automargin: true,
    title: {
        font: { size: 18 }
    },
    xaxis: {
        title: {
            standoff: 8.5,
            font: { size: 10, color: '#0000FF' }
        },
        ticks: 'inside',
        tickmode: 'auto',
        ticklen: 2,
        nticks: 10,
        tickfont: { size: 8 },
        linecolor: '#000000',
        linewidth: 1,
        mirror: true
    },
    yaxis: {
        title: {
            font: { size: 10, color: '#0000FF' }
        },
        ticks: 'inside',
        tickmode: 'auto',
        ticklen: 2,
        nticks: 15,
        tickfont: { size: 8 },
        linecolor: '#000000',
        linewidth: 1,
        mirror: true
    },
    legend: { xanchor: 'center', x: 0.5, yanchor: 'bottom', y: 0.985,
        borderwidth: 0,
        bgcolor: 'rgba(0,0,0,0)',
        font: {
            size: 8
        },
        orientation: 'h'
    },
    margin: { t: 45, b: 35, l: 45, r: 45 }
};

/* The default state for line plots */
const defaultLineFormatState = {
    voltage_line: {
        color: '#FF2200',
        width: 1
    },
    current_line: {
        color: '#004444',
        width: 1
    },
    c_o_line: {
        color: '#FF0000',
        width: 1
    },
    c_r_line: {
        color: '#0000FF',
        width: 1
    }
};

/* The default state for plot sliders */
const defaultSliderFormatState = {
    time_slider: {
        pad: {l: 0, t: 40, b: -10},
        ticklen: 3,
        minorticklen: 1,
        font: {
            size: 8,
            standoff: 35
        },
        currentvalue: {
            xanchor: 'center',
            offset: 10,
            visible: true,
            prefix: 'Time (s): ',
            font: {
                textAlign: 'right',
                color: '#0000FF',
                size: 10
            }
        }
    }
};

/* The default state for plot annotations */
const defaultAnnotationsState = {
    frequency_annotation: {
        xref: 'paper', yref: 'paper',
        x: 0.73, xanchor: 'left',
        y: -0.165, yanchor: 'center',
        text: 'Frequency (Hz): ',
        font: { textAlign: 'center', size: 10, color: '#0000FF' },
        showarrow: false
    }
};
const defaultExperimentalState = {
    experimental_data :{
        header: "N/A",
        voltage_init: "N/A",
        voltage_max: "N/A",
        voltage_min: "N/A",
        init_PN: "N/A",
        scan_rate: "N/A",
        segment: "N/A",
        voltage_sample_interval: "N/A",
        quiet_time: "N/A",
        sensitivity: "N/A",
    }
};
/* The default state for the plots (start off empty) */
const defaultState = {
    default_config: defaultConfigState,
    default_layout: defaultLayoutState,
    default_line_format: defaultLineFormatState,
    default_slider_format: defaultSliderFormatState,
    annotations: defaultAnnotationsState,
    cvPlotData: defaultCVPlotState,
    cvtPlotData: defaultCVTPlotState,
    concPlotData: defaultConcPlotState,
    heatmapPlotData: defaultHeatmapPlotState,
    cvPlotDataFull: defaultCVPlotState,
    cvtPlotDataFull: defaultCVTPlotState,
    concPlotDataFull: defaultConcPlotState,
    heatmapPlotDataFull: defaultHeatmapPlotState,
    plotDataSnapshot: null,
    cvPlotDataLoaded: defaultCVPlotState,
    cvtPlotDataLoaded: { ...defaultCVTPlotState,
        vt_data: { ...defaultCVTPlotState.vt_data,
            name: 'V(t) loaded',
            line: {
                color: '#0099ff',
                width: 1
            }
        },
        ct_data: { ...defaultCVTPlotState.ct_data,
            name: 'C(t) loaded',
            line: {
                color: '#CC00FF',
                width: 1
            }
        }
    },
    concPlotDataLoaded: defaultConcPlotState,
    heatmapPlotDataLoaded: defaultHeatmapPlotState,
    cycles_active: [false,false,false,false],
    time_arr_length: null,
    compare_data: false,
    experimental_state: defaultExperimentalState,
};

/* The reducer for the data plots */
const PlotDataReducer = (state = defaultState, action) => {
    switch(action.type) {
        case types.CV_DATA_PARSED:
            var cv_data = action.data[0];

            return {...state, cvPlotData: {
                cv_data: {...state.cvPlotData.cv_data, x: cv_data.x, y: cv_data.y}
            },
            cvPlotDataFull: {
                cv_data: {...state.cvPlotDataFull.cv_data, x: cv_data.x, y: cv_data.y}
            }
            };
        case types.CVT_DATA_PARSED:
            var vt_data = action.data[0];
            var ct_data = action.data[1];
            return {...state, 
                cvtPlotData: {
                    frequency: action.freq,
                    vt_data: {...state.cvtPlotData.vt_data, x: vt_data.x, y: vt_data.y},
                    ct_data: {...state.cvtPlotData.ct_data, x: ct_data.x, y: ct_data.y}
                },
                cvtPlotDataFull: {
                    frequency: action.freq,
                    vt_data: {...state.cvtPlotDataFull.vt_data, x: vt_data.x, y: vt_data.y},
                    ct_data: {...state.cvtPlotDataFull.ct_data, x: ct_data.x, y: ct_data.y}
                }
            };
        case types.CONC_DATA_PARSED:
            var conc_frames = action.frames;
            var conc_steps = action.steps;

            return {...state, 
                concPlotData: {
                    conc_x: action.x,
                    conc_frames: conc_frames,
                    conc_steps: conc_steps
                },
                concPlotDataFull: {
                    conc_x: action.x,
                    conc_frames: conc_frames,
                    conc_steps: conc_steps
                }
            };
        case types.HEATMAP_DATA_PARSED:
            var heatmap_frames = action.frames;
            var heatmap_steps = action.steps;
 
           //console.log(heatmap_frames);
            return {...state, 
                heatmapPlotData: {
                    heatmap_x: action.x,
                    heatmap_y: [0.0, 0.1],
                    heatmap_frames: heatmap_frames,
                    heatmap_steps: heatmap_steps
                },
                heatmapPlotDataFull: {
                    heatmap_x: action.x,
                    heatmap_y: [0.0, 0.1],
                    heatmap_frames: heatmap_frames,
                    heatmap_steps: heatmap_steps
                }
            };            
        case types.CV_PLOT_DATA_LOADED:
            return {...state, cvPlotDataLoaded: action.loaded};
        case types.CVT_PLOT_DATA_LOADED:
            var loadedData = action.loaded;
            return {...state, cvtPlotDataLoaded: { ...state.cvtPlotDataLoaded,
                vt_data: { ...state.cvtPlotDataLoaded.vt_data,
                    x: loadedData.vt_data.x,
                    y: loadedData.vt_data.y
                },
                ct_data: { ...state.cvtPlotDataLoaded.ct_data,
                    x: loadedData.ct_data.x,
                    y: loadedData.ct_data.y
                }
            }};
        case types.CONC_PLOT_DATA_LOADED:
            return {...state, concPlotDataLoaded: action.loaded};
        case types.HEATMAP_PLOT_DATA_LOADED:
            return {...state, heatmapPlotDataLoaded: action.loaded};
        case types.SHOW_DATA_LOADED:
            //console.log(state.cvPlotDataLoaded);
            //console.log(state.cvtPlotDataLoaded);
            //console.log(state.concPlotDataLoaded);
            //console.log(state.heatmapPlotDataLoaded);

            return {...state, compare_data: true};    
        case types.ITEM_TOGGLED:
            var index= action.index;
            //console.log(index);
            var cyclesActive= state.cycles_active;
            //console.log(cyclesActive);
            cyclesActive[index]= !cyclesActive[index];
            //console.log(cyclesActive);
            return {...state, cycles_active: cyclesActive};      
        case types.SET_OTHER_PLOT_DATA:
            return {...state, 
                time_arr_length: action.time_length, 
                num_cycles: action.num_cycles, 
                cycles_active: action.cycles_active};             
        case types.CYCLES_TOGGLED:
            var frequency = state.cvtPlotDataFull.frequency;
            var conc_x = state.concPlotDataFull.conc_x;
            var heatmap_x = state.heatmapPlotDataFull.heatmap_x;         

            var cv_data_slice_x= [];
            var vt_data_slice_x= [];
            var ct_data_slice_x= [];
            var cv_data_slice_y= [];
            var vt_data_slice_y= [];
            var ct_data_slice_y= [];
            var conc_frames_slice= [];

            var conc_steps_slice= [];
            var heatmap_frames_slice= [];
            var heatmap_steps_slice= [];

            var num_cycles= action.num_cycles;
            var cycles_active= action.cycles_active;
            var time_length= state.time_arr_length;
            state.num_cycles=num_cycles;
            state.cycles_active=cycles_active;

    //Slicing of all plot data
            for(var t = 0; t < num_cycles; t++) {
                //console.log(((time_length/num_cycles)*t));
                //console.log((((time_length/num_cycles)*(t+1))+1));
                cv_data_slice_x[t]=state.cvPlotDataFull.cv_data.x.slice(((time_length/num_cycles)*t),(((time_length/num_cycles)*(t+1))+1));
                cv_data_slice_y[t]=state.cvPlotDataFull.cv_data.y.slice(((time_length/num_cycles)*t),(((time_length/num_cycles)*(t+1))+1));

                ct_data_slice_x[t]=state.cvtPlotDataFull.ct_data.x.slice(((time_length/num_cycles)*t),(((time_length/num_cycles)*(t+1))+1));
                vt_data_slice_x[t]=state.cvtPlotDataFull.vt_data.x.slice(((time_length/num_cycles)*t),(((time_length/num_cycles)*(t+1))+1));
                ct_data_slice_y[t]=state.cvtPlotDataFull.ct_data.y.slice(((time_length/num_cycles)*t),(((time_length/num_cycles)*(t+1))+1));
                vt_data_slice_y[t]=state.cvtPlotDataFull.vt_data.y.slice(((time_length/num_cycles)*t),(((time_length/num_cycles)*(t+1))+1));
               
                conc_frames_slice[t]=state.concPlotDataFull.conc_frames.slice(((time_length/num_cycles)*t),(((time_length/num_cycles)*(t+1))+1));
                heatmap_frames_slice[t]=state.heatmapPlotDataFull.heatmap_frames.slice(((time_length/num_cycles)*t),(((time_length/num_cycles)*(t+1))+1));
                conc_steps_slice[t]=state.concPlotDataFull.conc_steps.slice(((time_length/num_cycles)*t),(((time_length/num_cycles)*(t+1))+1));
                heatmap_steps_slice[t]=state.heatmapPlotDataFull.heatmap_steps.slice(((time_length/num_cycles)*t),(((time_length/num_cycles)*(t+1))+1));
                
            }            
    //Current vs Voltage reconstruction
        //Reconstruction
            var cvxdata=[];
            var cvydata=[];
            //sliced data shall be concatenated based on if the value of the cycle is true
            for(var i=0; i<num_cycles; i++){
                for(var j=0; j<cv_data_slice_x[i].length; j++){
                    if(cycles_active[i]){
                        cvxdata.push(cv_data_slice_x[i][j]);
                    }
                    else{
                        cvxdata.push(null);
                    }
                }
                for(j=0; j<cv_data_slice_y[i].length; j++){
                    if(cycles_active[i]){
                        cvydata.push(cv_data_slice_y[i][j]);
                    }
                    else{
                        cvydata.push(null);
                    }
                }

            //console.log(cvxdata);

            }
            cv_data = {
                x: cvxdata,
                y: cvydata,
                yaxis: 'y2'
            };
            //console.log(cv_data);
            
    //Current & Voltage VS time reconstruction
        //Reconstruction


            var vtxdata=[];
            var vtydata=[];

            //sliced data shall be concatenated based on if the value of the cycle is true
            for(i=0; i<num_cycles; i++){
                for(j=0; j<vt_data_slice_x[i].length; j++){
                    if(cycles_active[i]){
                        vtxdata.push(vt_data_slice_x[i][j]);
                    }
                    else{
                        vtxdata.push(null);
                    }
                }
                for(j=0; j<vt_data_slice_y[i].length; j++){
                    if(cycles_active[i]){
                        vtydata.push(vt_data_slice_y[i][j]);
                    }
                    else{
                        vtydata.push(null);
                    }
                }
            }


            var ctxdata=[];
            var ctydata=[];
            for(i=0; i<num_cycles; i++){
                for(j=0; j<ct_data_slice_x[i].length; j++){
                    if(cycles_active[i]){
                        ctxdata.push(ct_data_slice_x[i][j]);
                    }
                    else{
                        ctxdata.push(null);
                    }

                }
                for(j=0; j<ct_data_slice_y[i].length; j++){
                    if(cycles_active[i]){
                        ctydata.push(ct_data_slice_y[i][j]);
                    }
                    else{
                        ctydata.push(null);
                    }
                }
            }
            vt_data = {
                x: vtxdata,
                y: vtydata,
            };
            ct_data = {
                x: ctxdata,
                y: ctydata,
            };
            //console.log(vt_data);
            //console.log(ct_data);
            
    //Concentration Vs Distance reconstruction
        //Reconstruction
            
            conc_frames = [];
            conc_steps = [];
            //console.log(conc_frames_slice);
            //sliced data shall be concatenated based on if the value of the cycle is true
            for(i=0; i<num_cycles; i++){
                for(j=0; j<conc_frames_slice[i].length; j++){
                    if(cycles_active[i]){
                        conc_frames.push(conc_frames_slice[i][j]);
                    }
                    else{
                        conc_frames.push({ 
                            name: null,
                            data: [{y: null},
                            {y: null}]
                        });
            

                    }
                }
                for(j=0; j<conc_steps_slice[i].length; j++){
                    if(cycles_active[i]){
                        conc_steps.push(conc_steps_slice[i][j]);
                    }
                    else{
                        conc_steps.push(null);
                    }
                }
            }
           // console.log(conc_frames);
    //Heatmap reconstruction
        //Reconstruction


            heatmap_frames = [];
            heatmap_steps = [];

            //sliced data shall be concatenated based on if the value of the cycle is true
            for(i=0; i<num_cycles; i++){
                for(j=0; j<heatmap_frames_slice[i].length; j++){
                    if(cycles_active[i]){
                        heatmap_frames.push(heatmap_frames_slice[i][j]);
                    }
                    else{
                        heatmap_frames.push({ 
                            name: null,
                            data: [{z: null}]
                        });
                    }
                }
                for(j=0; j<heatmap_steps_slice[i].length; j++){
                    if(state.cycles_active[i]){
                        heatmap_steps.push(heatmap_steps_slice[i][j]);
                    }
                    else{
                        heatmap_steps.push(null);
                    }
                }
            }
            //console.log("The cycle update");
        return {...state, 
            cvPlotData: {
                cv_data: {...state.cvPlotData.cv_data, x: cv_data.x, y: cv_data.y}
            },
            cvtPlotData: {
                frequency: frequency,
                vt_data: {...state.cvtPlotData.vt_data, x: vt_data.x, y: vt_data.y},
                ct_data: {...state.cvtPlotData.ct_data, x: ct_data.x, y: ct_data.y}
            },
            concPlotData: {
                conc_x: conc_x,
                conc_frames: conc_frames,
                conc_steps: conc_steps
            },
            heatmapPlotData: {
                heatmap_x: heatmap_x,
                heatmap_y: [0.0, 0.1],
                heatmap_frames: heatmap_frames,
                heatmap_steps: heatmap_steps
            }        
            };           
        case types.EXPERIMENT_LOADED:
            var experimental_data = action.data;
            
            console.log(experimental_data);
                    //plot data
                var compare_plot_data = {    
                    //voltage and current vs time graphs
                    voltage_vs_time: {
                        name: 'experimental voltage',
                        yaxis: 'y2',
                        x: experimental_data.time_data,
                        y: experimental_data.voltage_data
                    },
                    current_vs_time: {
                        name: 'experimental current',
                        x: experimental_data.time_data,
                        y: experimental_data.current_data
                    },
            
                    //current vs voltage graph
                    current_vs_voltage: { 
                        name: 'experimental data',
                        x: experimental_data.voltage_data.map(x => x*1e3),
                        y: experimental_data.current_data
                    }
                }
                //console.log(compare_plot_data);
            return {...state, 
                experimental_state: {
                    experimental_data: {...state.experimental_state.experimental_data, header: experimental_data.header, voltage_init: experimental_data.voltage_init, voltage_max: experimental_data.voltage_max, voltage_min: experimental_data.voltage_min, init_PN: experimental_data.init_PN, quiet_time: experimental_data.quiet_time, scan_rate: experimental_data.scan_rate, segment: experimental_data.segment, voltage_sample_interval: experimental_data.voltage_sample_interval, sensitivity: experimental_data.sensitivity},
                },
                cvPlotDataLoaded: {
                    cv_data: {...state.cvPlotDataLoaded.cv_data, x: compare_plot_data.current_vs_voltage.x, y: compare_plot_data.current_vs_voltage.y}
                },
                cvtPlotDataLoaded: {
                    frequency: "N/A",
                    vt_data: {...state.cvtPlotDataLoaded.vt_data, x: compare_plot_data.voltage_vs_time.x, y: compare_plot_data.voltage_vs_time.y},
                    ct_data: {...state.cvtPlotDataLoaded.ct_data, x: compare_plot_data.current_vs_time.x, y: compare_plot_data.current_vs_time.y}
                },
                concPlotDataLoaded: defaultConcPlotState,
                heatmapPlotDataLoaded:defaultHeatmapPlotState,   
                };   
        default:
            return state;
    };
};

export default PlotDataReducer;