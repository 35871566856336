import {types} from './ActionTypes.js';

/* Called when the control panel mode is changed */
const controlPanelModeSelected = (modeSelected) => {
    return {
        type: types.CONTROL_PANEL_MODE_CHANGED,
        mode: modeSelected
    };
};

/* Called when the page's state is changed */
const pageStateChanged = (pageState) => {
    return {
        type: types.PAGE_STATE_CHANGED,
        page: pageState
    }
}

/* Called after simulation is ran at least once (there is data to save) */
const simulationRan = (num_cycles, waveType) => {
    return {
        type: types.SIMULATION_RAN,
        numCycles: num_cycles,
        waveType: waveType
    };
};

/* Called after simulation data is loaded successfully */
const simulationLoaded = (is_experiment) => {
    return {
        type: types.SIMULATION_LOADED,
        isExperimental: is_experiment
    };
};

/* Called when a new file (or same file that has been edited) has been loaded */
const newFileLoaded = (newValue, newLastModified) => {
    return {
        type: types.NEW_FILE_LOADED,
        value: newValue,
        lastModified: newLastModified
    };
}

/* Action called when invalid file is loaded */
const inputFileInvalid = () => {
    return {
        type: types.INVALID_INPUT_FILE
    };
};

const UIActions = {
    controlPanelModeSelected,
    pageStateChanged,
    simulationRan,
    simulationLoaded,
    newFileLoaded,
    inputFileInvalid
};

export default UIActions;