import React from 'react';
import './styles.css';
import PlotWindowPanel from './PlotWindowPanel';
import ModifySpeciesPanel from './ModifySpeciesPanel';
import LoginPanel from './LoginPanel';
import {useSelector} from 'react-redux';

function PanelRight() {
    // Store Interfaces
    const state = useSelector(state => state.ui);

    // DOM Element Initialization
    var right_panel_view = null;
    switch(state.pageState) {
        case 'view_simulation':
            right_panel_view = <PlotWindowPanel />;
            break;
        case 'modify_species':
            right_panel_view = <ModifySpeciesPanel />;
            break;
        case 'login':
            right_panel_view = <LoginPanel />;
            break;
        default:
            right_panel_view = <PlotWindowPanel />;
            break;
    };

    return(
        <div className="panel_right">
            {right_panel_view}
            {/* Container for data visualization plots */}
        </div>
    );
};

export default PanelRight;