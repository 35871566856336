import React from 'react';
import './styles.css';

function HowToSimulationForm() {
    //This whole section is just jsx/html which gives an introduction to PaRedox and a tutorial on how to use it.
    return(
        <form className="howto_simulation_form">
            <div className="form_module" id="form_module_howto">                
                <h1>About PaRedox</h1>
                <p>PaRedox is a web-based application with the following description and goal:</p>
                <p>The PaRedox application connects bioelectrical simulations to an easy to use online interface that fulfills these tasks:  </p>
                    <ul>
                        <li>Accelerate the rate which results from oxidation-redox reactions can be gathered</li>
                        <li>Connect our simulation to a greater audience</li>
                        <li>Expedite the research process for bioelectronic interfaces</li>
                        <li>Display simulation data in a fluent and easy to understand way</li>
                        <li>Remove the need to run new physical trials for a set of parameters</li>
                    </ul>
              
                <h1>How to use PaRedox</h1>
                <p>The PaRedox App has two main interfaces, the left and right panels:</p>
                <p>The left panel contains the Create, Load, and Tutorial/About Tabs, while the right panel contains the Plots for simulation results and loaded experimental data, as well as the ability to view specific configurations of simulation cycles, when applicable.</p>
                    <h2>The Left Panel</h2>
                    <ul>
                        <li>The Create Tab allows one to set parameters for a Cyclic Voltammetry experiment simulation, run that simulation, and download the results.</li>
                        <li>The Create Tab has two main forms, one for Species parameters, and one for Wave Form parameters.</li>
                        <li>The Species form allows a user to select up to 5 redox-active chemical species in varying concentrations and initial percent oxidized (additional species can be added via the "+" button in the top right hand corner of the form)</li>
                        <li>The list of species available for simulation is based on those in the species list database, which can be edited as described later.</li>
                        <li>The Wave Form form allows a user to select desired voltage signal waveform, desired Minimum, Maximum, and starting voltages, the simulation scan rate, and the number of Cyclic Voltammetry cycles to simulate.</li>
                        <li>It also features a checkbox for reducing simulation data to increase performance.</li>
                        <li>The Create Tab also has the Modify Species list button, which directs users to a log-in page. If you have an account granted by an administrator, you may add, modify, or remove redox-active species from the available species list. </li>
                        <li>The Species Database Acess UI allows a user, once logged in, to add, modify, or remove redox-active species. To add a species, input desired parameter values for the species in the corresponding UI, and press Add/Modify Species. If you input the name of an existing species in the Species Name input box, it will modify the aspects of that species rather than adding an additional copy. To remove a species, select a species from the list of species to remove, and press the "Remove" button.</li>
                        <li>Note: You may not modify the Ferrocene or Ruthenium species or remove them, via the PaRedox application.</li>
                        <li>Note: You may perform other left panel functionalities as normal when modifying species, but the right panel will be covered by the species modification UI until you quit it by pressing the "Cancel" button.</li>
                        <li>Special notes for the Create Tab are that the Reduce Simulation Data button is on by default, and that simulation times vary. If the Reduce Simulation checkbox is unmarked, the simulation will take longer, and any downloaded simulation files will be larger, but more accurate.</li>
                        <li>The Load tab allows for a user to upload either previously saved .json format simulation data, or CSV-Formatted .txt files containing experimental data from the University of Maryland.</li>
                        <li>Once this data is loaded, it will appear on the plots on the Right Panel, and all available simulation parameters will be shown in the corresponding forms. This data can be loaded into the Create tab by pressing the Edit button.</li>
                        <li>By pressing the Compare Results button at the bottom of the Load Tab, the loaded data will be placed in the plots alongside the freshly simulated data from the Create Tab. This is useful for direct CV visual result comparisons.</li>
                    </ul>
                    <h2>The Right Panel</h2>
                    <ul>
                        <li>For all simulations besides those using the DPV Signal Type, there will be four plots which fill the right panel. Those featuring the DPV Signal Type will lack the Current vs Voltage graph as it is not properly simulated, and the DPV signal is a signal often used in experiments to approximate a triangular waveform.</li>
                        <li>The Current vs Voltage and Current and Voltage vs Time plots are the classic plots used in Cyclic Voltammetry experiments to display voltage and current characteristic data. </li>
                        <li>The Concentration vs Distance plot and Concentration Heatmap contain sliders which allow you to see the Concentrations of Oxidized and Reduced Species in respect to distance from the simulated electrode,  at a given time in the simulation. </li>
                        <li>In the future, the Concentration Heatmap will be modified to show 2D concentration values with respect to the electrode, rather than as a one dimensional distance.</li>
                        <li>If a simulation is ran or loaded with more than 1 recognizable cycle, the cycle selection UI will appear at the bottom of the plots section. Once the cycles you wish to view are selected, press the Update Plot Viewing Cycles button to finalize the cycles visible. </li>
                    </ul>
                    <h2>Logged-In Functionality</h2>
                    <ul>
                        <li>If you have an account with the PaRedox admin, you may additionally log in via the login panel in the top-left corner. </li>
                        <li>You may only modify or delete species which you have added, unless you are an admin, in which case you may access all species for modification besides Ferrocene and Ruthenium</li>
                        <li>To obtain an account, contact the PaRedox administrator email of "maxp@unl.edu"</li>
                    </ul>
            </div>
        </form>
    );
};

export default HowToSimulationForm;