import {types} from '../actions/ActionTypes.js';

/* The default state for web data */
const defaultState = {
    speciesDatabaseSynced: false,
    speciesDatabaseQueried: false,
    speciesDatabaseError: false,
    databaseErrorCode: 0,
    speciesData: [{
        name: "",
        text: ""
    }],
    simulationRunning: false,
    simulationError: false,
    simulationErrorCode: 0,
    simulationData: [],
    authenticatingUser: false,
    authenticationError: false,
    authenticationErrorCode: 0,
    sessionLoggedIn: false,
    sessionInvalid: false,
    sessionHidePassword: true,
    session_username: "",
    session_password: ""
};

/* The reducer for the species form module */
const HttpReducer = (state = defaultState, action) => {
    switch(action.type) {
        case types.SPECIES_DATABASE_QUERIED:
            return {...state, speciesDatabaseQueried: true, speciesDatabaseError: false, speciesDatabaseSynced: false};
        case types.SPECIES_DATA_COLLECTED:
            return {...state, speciesData: action.data, speciesDatabaseSynced: true, speciesDatabaseQueried: true, speciesDatabaseError: false};
        case types.SPECIES_DATABASE_UPDATED:
            return {...state, speciesDatabaseSynced: false};
        case types.SPECIES_DATABASE_ERROR:
            return {...state, speciesDatabaseError: true, databaseErrorCode: action.error, speciesDatabaseSynced: false, speciesDatabaseQueried: false};
        case types.SIMULATION_DATA_RECEIVED:
            return {...state, simulationRunning: false, simulationError: false, simulationData: action.data};
        case types.SIMULATION_ERROR:
            return {...state, simulationError: true, simulationErrorCode: action.error, simulationRunning: false};
        case types.SIMULATION_RUNNING:
            return {...state, simulationRunning: true};
        case types.SHOW_PASSWORD:
            var newSessionHidePassword = !state.sessionHidePassword;
            return {...state, sessionHidePassword: newSessionHidePassword};
        case types.AUTHENTICATING_USER:
            return {...state, sessionInvalid: false, authenticatingUser: true, authenticationError: false};
        case types.AUTHENTICATION_ERROR:
            return {...state, authenticatingUser: false, authenticationError: true, authenticationErrorCode: action.error};
        case types.AUTHENTICATION_SUCCESSFUL:
            return {...state, sessionLoggedIn: true, authenticatingUser: false, authenticationError: false, session_username: action.username};
        case types.AUTHENTICATION_FAILED:
            return {...state, sessionInvalid: true, sessionLoggedIn: false, authenticatingUser: false, authenticationError: false};
        case types.LOGOUT_USER:
            return {...state, sessionInvalid: false, sessionLoggedIn: false, authenticatingUser: false, authenticationError: false};
        case types.TEXT_FIELD_CHANGED:
            if(action.form === 'session') {
                var stateName = "session_" + action.field;
                return {...state, [stateName]: action.text};
            }
            else{
                return state;
            };
        default:
            return state;
    };
};

export default HttpReducer;