//list of all action types within the various action files
export const types = {
    SPECIES_DATA_COLLECTED: 'SPECIES_DATA_COLLECTED',
    SPECIES_DATABASE_QUERIED: 'SPECIES_DATABASE_QUERIED',
    SPECIES_DATABASE_ERROR: 'SPECIES_DATABASE_ERROR',
    SPECIES_DATABASE_UPDATED: 'SPECIES_DATABASE_UPDATED',
    SHOW_PASSWORD: 'SHOW_PASSWORD',
    AUTHENTICATING_USER: 'AUTHENTICATING_USER',
    AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
    AUTHENTICATION_SUCCESSFUL: 'AUTHENTICATION_SUCCESSFUL',
    AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
    OPTION_SELECTED: 'OPTION_SELECTED',
    TAB_SELECTED: 'TAB_SELECTED',
    ITEM_TOGGLED: 'ITEM_TOGGLED',
    TEXT_FIELD_CHANGED: 'TEXT_FIELD_CHANGED',
    NUMBER_FIELD_CHANGED: 'NUMBER_FIELD_CHANGED',
    NUMBER_FIELD_HALVED: 'NUMBER_FIELD_HALVED',
    NUMBER_FIELD_DOUBLED: 'NUMBER_FIELD_DOUBLED',
    LIST_ITEM_ADDED: 'LIST_ITEM_ADDED',
    LIST_ITEM_REMOVED: 'LIST_ITEM_REMOVED',
    PARSING_PLOT_DATA: 'PARSING_PLOT_DATA',
    PARSING_ERROR: 'PARSING_ERROR',
    CV_DATA_PARSED: 'CV_DATA_PARSED',
    CVT_DATA_PARSED: 'CVT_DATA_PARSED',
    CONC_DATA_PARSED: 'CONC_DATA_PARSED',
    HEATMAP_DATA_PARSED: 'HEATMAP_DATA_PARSED',
    SIMULATION_DATA_RECEIVED: 'SIMULATION_DATA_RECEIVED',
    SIMULATION_RUNNING: 'SIMULATION_RUNNING',
    SIMULATION_ERROR: 'SIMULATION_ERROR',
    SAVE_WAVEFORM_DATA: 'SAVE_WAVEFORM_DATA',
    SAVE_SPECIES_DATA: 'SAVE_SPECIES_DATA',
    WAVEFORM_DATA_LOADED: 'WAVEFORM_DATA_LOADED',
    SPECIES_DATA_LOADED: 'SPECIES_DATA_LOADED',
    CV_PLOT_DATA_LOADED: 'CV_PLOT_DATA_LOADED',
    CVT_PLOT_DATA_LOADED: 'CVT_PLOT_DATA_LOADED',
    CONC_PLOT_DATA_LOADED: 'CONC_PLOT_DATA_LOADED',
    HEATMAP_PLOT_DATA_LOADED: 'HEATMAP_PLOT_DATA_LOADED',
    EDIT_DATA: 'EDIT_DATA',
    CONTROL_PANEL_MODE_CHANGED: 'CONTROL_PANEL_MODE_CHANGED',
    PAGE_STATE_CHANGED: 'PAGE_STATE_CHANGED',
    SIMULATION_RAN: 'SIMULATION_RAN',
    SIMULATION_LOADED: 'SIMULATION_LOADED',
    NEW_FILE_LOADED: 'NEW_FILE_LOADED',
    INVALID_INPUT_FILE: 'INVALID_INPUT_FILE',
    CYCLES_TOGGLED: 'CYCLES_TOGGLED',
    SET_OTHER_PLOT_DATA: 'SET_OTHER_PLOT_DATA',
    VALIDATE_SPECIES_INPUTS: 'VALIDATE_SPECIES_INPUTS',
    ADD_MODIFY_SPECIES: 'ADD_MODIFY_SPECIES',
    REMOVE_SPECIES: 'REMOVE_SPECIES',
    SHOW_DATA_LOADED: 'SHOW_DATA_LOADED',
    EXPERIMENT_LOADED: 'EXPERIMENT_LOADED',
    LOGOUT_USER: 'LOGOUT_USER',
};