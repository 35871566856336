import WaveformReducer from './WaveformReducer';
import SpeciesReducer from './SpeciesReducer';
import PlotDataReducer from './PlotDataReducer';
import UIReducer from './UIReducer';
import HttpReducer from './HttpReducer';
import {combineReducers} from 'redux';
import SpeciesModReducer from './SpeciesModReducer';

/* The root reducer containing nested reducers */
const RootReducer = combineReducers({
    ui: UIReducer,
    form: combineReducers({
        waveform: WaveformReducer,
        species: SpeciesReducer,
        speciesmod: SpeciesModReducer

    }),
    http: HttpReducer,
    plotData: PlotDataReducer
});

export default RootReducer;