import React from 'react';
import './styles.css';
import ControlWindowPanel from './ControlWindowPanel';

function PanelLeft() {
    // DOM Element Initialization
    var control_window_panel = <ControlWindowPanel />;

    return(
        <div className="panel_left">
            {control_window_panel}
            <img src={process.env.PUBLIC_URL + '/maryland.png'} alt="University of Maryland" className="logo" />
            <p >Made in collaboration with the <a href="https://www.umd.edu/" target="_blank">University of Maryland, College Park</a> and the continuing research and organizational contributions of <a href="https://www.ibbr.umd.edu/profiles/gregory-payne" target="_blank">Gregory F. Payne</a>, <a href="https://bentley.umd.edu/" target="_blank">William E. Bentley</a>, <a href="https://www.ibbr.umd.edu/taxonomy/term/453" target="_blank">The Payne Group</a>, and everyone who continues to advance Redox-Linked Bioelectronics. </p>

            {/* Container for the user control panel box */}
        </div>
    );
};

export default PanelLeft;