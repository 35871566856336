import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import FormActions from '../../../../actions/FormActions';
import SpeciesModuleTab from './input/SpeciesModuleTab';
import ViewModuleSpecies from './ViewModuleSpecies';
import ViewModuleSignal from './ViewModuleSignal';
import ViewModuleExperimental from './ViewModuleExperimental';
import LoadSimulationButton from '../input/LoadSimulationButton';
import EditSimulationButton from '../input/EditSimulationButton';
import CompareSimulationButton from '../input/CompareSimulationButton';

function LoadSimulationForm() {
    // Store Interfaces
    const state = useSelector(state => state.form);
    const uiState = useSelector(state => state.ui);
    const dispatch = useDispatch();

    // Called whenever a species tab is selected
    const speciesChangedEvent = event => {
        dispatch(FormActions.tabSelected('species_load', event.target.name, Number(event.target.value)));
    };

    // DOM Element Initialization
    var species_tabs = null;
    var experimental_module= null;
    var species_module= <ViewModuleSpecies />;
    var waveform_module= <ViewModuleSignal />;
    var edit_sim_button= <EditSimulationButton />;

    if(uiState.loadedSimulation) {
        if(uiState.experimentalLoaded){
            experimental_module=<ViewModuleExperimental />
            species_tabs=null;
            species_module=null;
            waveform_module=null;
        }
        else{
            species_tabs = state.species.loadedSpecies.speciesList.map((item, index) =>
                <SpeciesModuleTab key={index.toString()}
                    tabName={"tab_species_" + String(index + 1)} 
                    tabValue={index}
                    tabLabel={item.species_name}  
                    speciesSelected={state.species.loadedSpecies.speciesTabSelected}
                    onSpeciesChanged={speciesChangedEvent} />
            );
            species_module= <ViewModuleSpecies />;
            waveform_module= <ViewModuleSignal />;        
        }
    } else {
        species_tabs = 
            <SpeciesModuleTab 
                tabName={"tab_species_1"}
                tabValue={0}
                tabLabel={"No Data"}
                speciesSelected={state.species.loadedSpecies.speciesTabSelected}
                onSpeciesChanged={speciesChangedEvent}
            />
    }

    return(
        <form className="load_simulation_form">
            <div className="load_button_bar">
                <LoadSimulationButton />
                {edit_sim_button}
            </div>
            <div className="species_tab_bar" id="load_species_tab_bar">
                {species_tabs}
            </div>
            {experimental_module}
            {species_module}
            {waveform_module}
            <CompareSimulationButton />
        </form>
    );
};

export default LoadSimulationForm;