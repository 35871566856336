export const fullUrl = "http://paredox.unl.edu:8000/backend/";
export const wsUrl = "ws://paredox.unl.edu:8000/backend/";
//export const fullUrl = "http://localhost:8000/backend/";
//export const wsUrl = "ws://localhost:8000/backend/";

const Urls = {
    fullUrl,
    wsUrl
};

export default Urls;
