import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import FormActions from '../../../../actions/FormActions';

function FormModuleSignal() {
    // Store Interfaces
    const state = useSelector(state => state.form.waveform);
    const formName = 'waveform';
    const dispatch = useDispatch();

    // Collects the signal types currently available to the user
    function collectSignalTypeOptions() {
        return state.waveform_types.map((item, index) =>
            <option key={index.toString()} value = {index.toString()}>{item}</option>
        );
    }

    // Called number field is unfocused - validates value
    const validateNumberFieldEvent = event => {
        var value = Number(event.target.value);
        if(isNaN(value)) { // If input is not a number set to default value
            value = Number(event.target.default);
        } else if(value < event.target.min) { // If below minimum set to minimum value
            value = event.target.min;
        } else if(value > event.target.max) { // If above maximum set to maximum value
            value = event.target.max;
        };

        // If value was updated dispatch new value
        if(value !== event.target.value) {
            dispatch(FormActions.numberFieldChanged(formName, event.target.name, value));
        };
    };

    // Called when a number field is changed
    const numberFieldChangedEvent = event => {
        dispatch(FormActions.numberFieldChanged(formName, event.target.name, event.target.value));
    };

    // Called when option is selected from drop-down list
    const optionSelected = event => {
        dispatch(FormActions.optionSelected(formName, event.target.value));
    };
    const toggleItemEvent = event => {
        dispatch(FormActions.itemToggled(formName, event.target.name));
    };
    // DOM Element Initialization
    var signal_type_options = collectSignalTypeOptions();
    var signal_type_select = 
        <select className="select_signal_type" name="signalTypeSelect" onChange={optionSelected}>
            {signal_type_options} 
        </select>;

    return(
        <div className="form_module" id="form_module_signal">
            <h4>Wave Form</h4>
            <label>
                Signal Type:
                {signal_type_select}
            </label>
            <label>
                Min Voltage (V):
                <input type="number" className="input_min_voltage" name="minVoltage" min="-1000" max="1000" step="0.1" value={state.waveform_minVoltage} default="-0.5" onChange={numberFieldChangedEvent} onBlur={validateNumberFieldEvent} />
            </label>
            <label>
                Max Voltage (V):
                <input type="number" className="input_max_voltage" name="maxVoltage" min="-1000" max="1000" step="0.1" value={state.waveform_maxVoltage} default="0.5" onChange={numberFieldChangedEvent} onBlur={validateNumberFieldEvent} />
            </label>
            <label>
                Starting Voltage (V):
                <input type="number" className="input_start_voltage" name="startVoltage" min="-1000" max="1000" step="0.1" value={state.waveform_startVoltage} default="0.0" onChange={numberFieldChangedEvent} onBlur={validateNumberFieldEvent} />
            </label>
            <label>
                Scan Rate (mV/s):
                <input type="number" className="input_scan_rate" name="scanRate" min="10" max="500" step="5" value={state.waveform_scanRate} default="100" onChange={numberFieldChangedEvent} onBlur={validateNumberFieldEvent} />
            </label>
            <label>
                Cycles to Simulate:
                <input type="number" className="input_run_cycles" name="runCycles" min="1" max="4" step="1" value={state.waveform_runCycles} default="1" onChange={numberFieldChangedEvent} onBlur={validateNumberFieldEvent} />
            </label>
            <label>
                Reduce Simulation Data <br/>  (Increases speed, reduces result data):
                <input type="checkbox" className="input_reduceData" name="waveform_reduceData" value={state.waveform_reduceData} checked={state.waveform_reduceData} onChange={toggleItemEvent} />
            </label>
            <label>
                Add Diffusion and Chemical Noise to Simulation<br/>  (Simulates Noise, Reduces Simulation Speed):
                <input type="checkbox" className="input_noisyData" name="waveform_noisyData" value={state.waveform_noisyData} checked={state.waveform_noisyData} onChange={toggleItemEvent} />
            </label>
        </div>
    );
}

export default FormModuleSignal;