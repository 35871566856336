import {types} from './ActionTypes';
/* These actions are used for interaction with data plots */

/* Action called when simulation data is being parsed */
const parsingData = () => {
    return {
        type: types.PARSING_PLOT_DATA
    };
};

/* Action called when parsing simulation data has an error */
const dataParsingError = () => {
    return {
        type: types.PARSING_ERROR
    };
};

/* Action called when data is successfully parsed */
const cvDataParsed = (cvData) => {
    return {
        type: types.CV_DATA_PARSED,
        data: [cvData]
    };
};

const cvtDataParsed = (frequency, vtData, ctData) => {
    return {
        type: types.CVT_DATA_PARSED,
        freq: frequency,
        data: [vtData, ctData]
    };
};

const concDataParsed = (dist_arr, conc_frames, conc_steps) => {
    return {
        type: types.CONC_DATA_PARSED,
        x: dist_arr,
        frames: conc_frames,
        steps: conc_steps
    };
};

const heatmapDataParsed = (dist_arr, heatmap_frames, heatmap_steps) => {
    return {
        type: types.HEATMAP_DATA_PARSED,
        x: dist_arr,
        frames: heatmap_frames,
        steps: heatmap_steps
    };
};

const itemToggled = (index) => {
    return {
        type: types.ITEM_TOGGLED,
        index: index
    };
};


const cyclesToggled= (num_cycles,cycles_active)=>{
    console.log("Plots have been updated with new cycles");
    return {
        type: types.CYCLES_TOGGLED,
        num_cycles: num_cycles,
        cycles_active: cycles_active
    };
};

const setOtherPlotData= (time_arr_length, num_cycles, cycles_active) =>{
    return{
        type: types.SET_OTHER_PLOT_DATA,
        time_length: time_arr_length,
        num_cycles: num_cycles,
        cycles_active: cycles_active
    };
}

/* Create an asynchronous action for parsing simulation data for plot usage */
const parsePlotData = (simulationData, num_cycles) => {
    return function(dispatch, getState) {
        dispatch(parsingData());

        // Begin parsing plot data
        var signal_arr = simulationData.E.map(function(x) {return x * 1e3});
        var time_arr = simulationData.t;
        var dist_pts = simulationData.j;
        var dist_dx = simulationData.dx;
        var dist_arr = Array.from(Array(dist_pts).keys()).map(function(x) {return x * dist_dx});
        var current_arr = simulationData.I;
        var conc_initial = simulationData.C;
        var conc_o = simulationData.C_O;
        var conc_r = simulationData.C_R;
        var initial_cycles_active=[false,false,false,false];
        var freq = 1.0 / (2.0 * (simulationData.v_max - simulationData.v_min) / simulationData.scan_rate);

        for(var t = 0; t < time_arr.length; t++) {
            conc_o[t] = conc_o[t].map(function(x) {return x / conc_initial});
            conc_r[t] = conc_r[t].map(function(x) {return x / conc_initial});
        }

        //set each cycle initially active as true
        for(t = 0; t < num_cycles; t++) {
            initial_cycles_active[t]=true;
        }
        //add step to take the sliced cycles, and add them together.
        //console.log(initial_cycles_active);

        var vtData = {
            x: time_arr,
            y: signal_arr,
            yaxis: 'y2'
        };
        var ctData = {
            x: time_arr,
            y: current_arr
        };
        var cvData = {
            x: signal_arr,
            y: current_arr
        };
                // Dispatch data for cv and cvt plots

        dispatch(cvDataParsed(cvData));
        dispatch(cvtDataParsed(freq, vtData, ctData));

        // Dispatch data for concentration and heatmap plots
        var conc_frames = [];
        var heatmap_frames = [];
        var conc_steps = [];
        var heatmap_steps = [];


        for(t = 0; t < time_arr.length; t++) {
            var time_label = time_arr[t].toFixed(2);

            conc_frames.push({ 
                data: [{y: conc_o[t]},
                       {y: conc_r[t]}]
            });

            heatmap_frames.push({ 
                data: [{z: [conc_o[t], conc_o[t]]}]
            });

            conc_steps.push({ label: time_label, method: 'skip' });

            heatmap_steps.push({ label: time_label, method: 'skip' });
        };
 
        dispatch(concDataParsed(dist_arr, conc_frames, conc_steps));
        dispatch(heatmapDataParsed(dist_arr, heatmap_frames, heatmap_steps));
        dispatch(setOtherPlotData(time_arr.length, num_cycles, initial_cycles_active));

    };
};

const PlotActions = {
    parsingData,
    dataParsingError,
    cvDataParsed,
    cvtDataParsed,
    concDataParsed,
    heatmapDataParsed,
    parsePlotData,
    itemToggled,
    cyclesToggled
};

export default PlotActions;