import React from 'react';
import './styles.css';
import PanelLeft from './PanelLeft';
import PanelRight from './PanelRight';

function PanelContainer() {
    // DOM Element Initialization
    var panel_left = <PanelLeft />;
    var panel_right = <PanelRight />;

    return(
        <div className="panel_container">
            {panel_left}
            {/* Container for left side of page - panel for user input */}

            {panel_right}
            {/* Container for right side of page - panel for displaying plots and species modification forms */}
        </div>
    );
};

export default PanelContainer;