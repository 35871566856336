import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import PlotActions from '../../../../actions/PlotActions';

function CycleSelector() {
    // Store Interfaces
    const state = useSelector(state => state);
    const httpState = useSelector(state => state.http);
    const dispatch = useDispatch();
    
    var num_cycles= state.ui.savedNumCycles;

    //console.log(num_cycles);
    // Called when the button is clicked - access database panel
    function updatePlots() {
        
        //alert(state.plotData.cycles_active);
        dispatch(PlotActions.cyclesToggled(num_cycles,state.plotData.cycles_active));

    }
    const toggleItemEvent = event => {
        //console.log(event.target.name);
        dispatch(PlotActions.itemToggled(event.target.name));
    };
    var updatePlotsButton= <button className="updatePlots" onClick={updatePlots} disabled={!state.ui.simulationHasRan || httpState.simulationRunning}> Update Plot Viewing Cycles </button>;
    var checkbox1= <React.Fragment> 
        <input type="checkbox" className="input_activeCycle" name="0" value={state.plotData.cycles_active[0]} checked={state.plotData.cycles_active[0]} onChange={toggleItemEvent} disabled={!state.ui.simulationHasRan || httpState.simulationRunning} />  
        <label> Cycle 1</label>
        </React.Fragment>;
    var checkbox2= <React.Fragment> 
        <input type="checkbox" className="input_activeCycle" name="1" value={state.plotData.cycles_active[1]} checked={state.plotData.cycles_active[1]} onChange={toggleItemEvent} disabled={!state.ui.simulationHasRan || httpState.simulationRunning} />
        <label> Cycle 2</label>
        </React.Fragment>;
    var checkbox3= <React.Fragment> 
        <input type="checkbox" className="input_activeCycle" name="2" value={state.plotData.cycles_active[2]} checked={state.plotData.cycles_active[2]} onChange={toggleItemEvent} disabled={!state.ui.simulationHasRan || httpState.simulationRunning} />
        <label> Cycle 3</label>
    </React.Fragment>;
    var checkbox4= <React.Fragment> 
        <input type="checkbox" className="input_activeCycle" name="3" value={state.plotData.cycles_active[3]} checked={state.plotData.cycles_active[3]} onChange={toggleItemEvent} disabled={!state.ui.simulationHasRan || httpState.simulationRunning} />
        <label> Cycle 4</label>
    </React.Fragment> ;
    switch(parseInt(num_cycles)) {
        case 1:
            updatePlotsButton=null;
            checkbox1=null;
            checkbox2=null;
            checkbox3=null;
            checkbox4=null;
            break;
        case 2:

            checkbox3=null;
            checkbox4=null;
            break;
        case 3:

            checkbox4=null;
            break;
        case 4:

            break;            
        default:

            console.log(num_cycles);
            break;
    };
    return (
        <React.Fragment>
        {updatePlotsButton}
        <br></br>
        {checkbox1}
        {checkbox2}
        {checkbox3}
        {checkbox4}
        </React.Fragment>

    );
};

export default CycleSelector;