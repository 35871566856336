import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import SimulationActions from '../../../../actions/SimulationActions';

function CompareSimulationButton() {
    // Store Interfaces
    const state = useSelector(state => state);
    const httpState = useSelector(state => state.http);
    const dispatch = useDispatch();

    // Called when the button is clicked
    const buttonClickedEvent = event => {
        event.preventDefault();
        compareSimulationData();
    };

    // Called when the button is clicked - load a simulation file
    function compareSimulationData() {
        dispatch(SimulationActions.showDataLoaded());

    }

    return (
        <button className="compare_simulation_button" onClick={buttonClickedEvent} disabled={!(state.ui.pageState === 'view_simulation' && state.ui.loadedSimulation) || httpState.simulationRunning}>
            Compare Results
        </button>
    );
};

export default CompareSimulationButton;