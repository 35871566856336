import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import UIActions from '../../../../actions/UIActions';
import SimulationActions from '../../../../actions/SimulationActions';

function EditSimulationButton() {
    // Store Interfaces
    const state = useSelector(state => state);
    const httpState = useSelector(state => state.http);
    const dispatch = useDispatch();

    // Called when the button is clicked
    const buttonClickedEvent = event => {
        event.preventDefault();
        editSimulationData();
    };

    // Attempt to match a species name against the species loaded from the database
    function getSpeciesOption(species_name) {
        for(var i = 0; i < httpState.speciesData.length; i++) {
            if(species_name === httpState.speciesData[i].name) {
                return i;
            };
        };
        return 0;
    };

    // Attempt to match a waveform name against the list of types
    function getWaveformOption(waveform_type) {
        for(var i = 0; i < state.form.waveform.waveform_types.length; i++) {
            if(waveform_type === state.form.waveform.waveform_types[i]) {
                return i;
            };
        };
        return 0;
    };

    // Called when the button is clicked - load a simulation file
    function editSimulationData() {
        var loadedSpecies = state.form.species.loadedSpecies;
        var speciesList = [];
        var species_array = loadedSpecies.speciesList;
        console.log(loadedSpecies);
        for(var i = 0; i < species_array.length; i++) {
            var species_option_selected = getSpeciesOption(species_array[i].species_name);
            speciesList.push({
                species_option_selected,
                species_concentration: species_array[i].species_concentration,
                species_percentOxidized: species_array[i].species_percentOxidized,
                species_enabled: true
            });
        };
        var species_data = {
            maximumSpecies: loadedSpecies.maximumSpecies,
            speciesTabSelected: 0,
            speciesList
        };
        dispatch(SimulationActions.editSpeciesData(species_data));

        var loadedWaveform = state.form.waveform.loadedWaveform;
        var waveform_option_selected = getWaveformOption(loadedWaveform.waveform_option_selected);
        var waveform_data = {
            waveform_option_selected,
            waveform_minVoltage: loadedWaveform.waveform_minVoltage,
            waveform_maxVoltage: loadedWaveform.waveform_maxVoltage,
            waveform_startVoltage: loadedWaveform.waveform_startVoltage,
            waveform_scanRate: loadedWaveform.waveform_scanRate,
            waveform_runCycles: loadedWaveform.waveform_runCycles,
            waveform_reduceData: loadedWaveform.waveform_reduceData,
            waveform_noisyData: loadedWaveform.waveform_noisyData,
        };
        dispatch(SimulationActions.editWaveformData(waveform_data));
        dispatch(UIActions.controlPanelModeSelected("create"));
    };

    return (
        <button className="edit_simulation_button" onClick={buttonClickedEvent} disabled={!(state.ui.pageState === 'view_simulation' && state.ui.loadedSimulation) || httpState.simulationRunning || state.ui.experimentalLoaded}>
            Edit
        </button>
    );
};

export default EditSimulationButton;