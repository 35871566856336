import {types} from '../actions/ActionTypes.js';

/* The default parameter values for the waveform form module*/
const defaultState = {
    waveform_types: ['Triangle', 'Square', 'Sinusoidal', 'Sawtooth', 'DPV'],
    waveform_alias: ['triangle', 'square', 'sine', 'saw', 'dpv'],
    waveform_option_selected: 0,
    waveform_minVoltage: -0.5,
    waveform_maxVoltage: 0.5,
    waveform_startVoltage: 0.0,
    waveform_scanRate: 100,
    waveform_runCycles: 1,
    waveform_reduceData: true,
    waveform_noisyData: false,
    loadedWaveform: null
};

/* The reducer for the waveform form module */
const WaveformReducer = (state = defaultState, action) => {
    if(action.form === 'waveform') {
        var stateName = "waveform_";
        switch(action.type) {
            case types.OPTION_SELECTED:
                return {...state, waveform_option_selected: action.selected};
            case types.NUMBER_FIELD_CHANGED:
                stateName += action.field;
                return {...state, [stateName]: action.value};
            case types.SAVE_WAVEFORM_DATA:
                var waveform_option_selected = state.waveform_option_selected;
                var waveform_minVoltage = state.waveform_minVoltage;
                var waveform_maxVoltage = state.waveform_maxVoltage;
                var waveform_startVoltage = state.waveform_startVoltage;
                var waveform_scanRate = state.waveform_scanRate;
                var waveform_runCycles = state.waveform_runCycles;
                var waveform_reduceData = state.waveform_reduceData;
                var waveform_noisyData = state.waveform_noisyData;
                
                var newSnapshot = {
                    waveform_option_selected,
                    waveform_minVoltage,
                    waveform_maxVoltage,
                    waveform_startVoltage,
                    waveform_scanRate,
                    waveform_runCycles,
                    waveform_reduceData,
                    waveform_noisyData
                };
                return {...state, waveformSnapshot: newSnapshot};
            case types.WAVEFORM_DATA_LOADED:
                var waveform_loaded= action.loaded;
                if (typeof waveform_loaded.waveform_option_selected == 'undefined' || typeof waveform_loaded.waveform_option_selected != 'number') {
                    waveform_loaded.waveform_option_selected= "Undefined";
                }
                if (typeof waveform_loaded.waveform_minVoltage == 'undefined' || typeof waveform_loaded.waveform_minVoltage != 'number') {
                    waveform_loaded.waveform_minVoltage= "Undefined";
                }
                if (typeof waveform_loaded.waveform_maxVoltage == 'undefined' || typeof waveform_loaded.waveform_maxVoltage != 'number') {
                    waveform_loaded.waveform_maxVoltage= "Undefined";
                }
                if (typeof waveform_loaded.waveform_startVoltage == 'undefined' || typeof waveform_loaded.waveform_startVoltage != 'number') {
                    waveform_loaded.waveform_startVoltage= "Undefined";
                }
                if (typeof waveform_loaded.waveform_scanRate == 'undefined' || typeof waveform_loaded.waveform_scanRate != 'number') {
                    waveform_loaded.waveform_scanRate= "Undefined";
                }
                if (typeof waveform_loaded.waveform_runCycles == 'undefined' || typeof waveform_loaded.waveform_runCycles != 'number') {
                    waveform_loaded.waveform_runCycles= "Undefined";
                }
                if (typeof waveform_loaded.waveform_reduceData == 'undefined' || typeof waveform_loaded.waveform_reduceData != 'boolean') {
                    waveform_loaded.waveform_reduceData= "Undefined";
                }
                if (typeof waveform_loaded.waveform_noisyData == 'undefined' || typeof waveform_loaded.waveform_noisyData != 'boolean') {
                    waveform_loaded.waveform_noisyData= "Undefined";
                }                
                return {...state, loadedWaveform: waveform_loaded};
            case types.EDIT_DATA:
                var waveformData = action.data;
                if (waveformData.waveform_option_selected === "Undefined") {
                    waveformData.waveform_option_selected= defaultState.waveform_option_selected;
                }
                if (waveformData.waveform_minVoltage === "Undefined") {
                    waveformData.waveform_minVoltage= defaultState.waveform_minVoltage;
                }
                if (waveformData.waveform_maxVoltage === "Undefined") {
                    waveformData.waveform_maxVoltage= defaultState.waveform_maxVoltage;
                }
                if (waveformData.waveform_startVoltage === "Undefined") {
                    waveformData.waveform_startVoltage= defaultState.waveform_startVoltage;
                }
                if (waveformData.waveform_scanRate === "Undefined") {
                    waveformData.waveform_scanRate= defaultState.waveform_scanRate;
                }
                if (waveformData.waveform_runCycles === "Undefined") {
                    waveformData.waveform_runCycles= defaultState.waveform_runCycles;
                }
                if (waveformData.waveform_reduceData === "Undefined") {
                    waveformData.waveform_reduceData= defaultState.waveform_reduceData;
                }
                if (waveformData.waveform_noisyData === "Undefined") {
                    waveformData.waveform_noisyData= defaultState.waveform_noisyData;
                }                
                return {...state, 
                    waveform_option_selected: waveformData.waveform_option_selected,
                    waveform_minVoltage: waveformData.waveform_minVoltage,
                    waveform_maxVoltage: waveformData.waveform_maxVoltage,
                    waveform_startVoltage: waveformData.waveform_startVoltage,
                    waveform_scanRate: waveformData.waveform_scanRate,
                    waveform_runCycles: waveformData.waveform_runCycles,
                    waveform_reduceData: waveformData.waveform_reduceData,
                    waveform_noisyData: waveformData.waveform_noisyData
                };
            case types.ITEM_TOGGLED:
                var eventName= action.item;
                if(eventName==="waveform_reduceData"){
                    var waveformReduceData = !state.waveform_reduceData;
                    return {...state, waveform_reduceData: waveformReduceData};    
                }
                else if(eventName==="waveform_noisyData"){
                    var waveformNoisyData = !state.waveform_noisyData;
                    return {...state, waveform_noisyData: waveformNoisyData};                     
                }
                else{
                    return state;               
                }       
            default:
                return state;
        };
    } else {
        return state;
    };
};

export default WaveformReducer;