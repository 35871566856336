import React from 'react';
import Plot from 'react-plotly.js';
import './styles.css';
import {useSelector} from 'react-redux';
import { frechetDistance, shapeSimilarity } from 'curve-matcher';


function CVPlot(props) {
    // Store Interfaces
    const state = useSelector(state => state.plotData);
    const UIstate = useSelector(state => state.ui);

    // Default layout properties
    var frechet= null;
    var similarity= null;
    var comparestring= null;
    var config = state.default_config;
    var layout = state.default_layout;
    var new_layout = { ...layout,
        hovermode: 'closest',
        title: { ...layout.title, text: 'Current vs Voltage' },
        xaxis: { ...layout.xaxis,
            title: { ...layout.xaxis.title, text: 'Voltage (mV)' }
        },
        yaxis: { ...layout.yaxis,
            title: { ...layout.yaxis.title, text: 'Current (uA)' }
        },
        automargin: true,
        showlegend:true,
    };
    var loadedData= {
        visible: state.compare_data,
        name: 'I(V) loaded',
        type: 'scattergl',
        x: [0.0, 0.0], y: [0.0, 0.0],
        mode: 'lines+markers',
        marker: {
            size: 7,
            opacity: 0
        },
        line: {
            color: '#0099ff',
            width: 1
        }
    };
    if(state.compare_data){
        loadedData.x=state.cvPlotDataLoaded.cv_data.x;
        loadedData.y=state.cvPlotDataLoaded.cv_data.y;
    }
    var cv_data={
        visible: UIstate.simulationHasRan,
        name: 'I(V)',
        type: 'scattergl',
        x: [0.0, 0.0], y: [0.0, 0.0],
        mode: 'lines+markers',
        marker: {
            size: 7,
            opacity: 0
        },
        line: {
            color: '#FF2200',
            width: 1
        }
    };
    if(UIstate.simulationHasRan){
    cv_data=state.cvPlotData.cv_data;
    }
    if(UIstate.simulationHasRan && state.compare_data){

        var frechetSim= [];
        var frechetLoad= [];
        cv_data.x.forEach(function (item, index){
            frechetSim.push({x: cv_data.x[index], y: cv_data.y[index]});
        });
        loadedData.x.forEach(function (item, index){
            frechetLoad.push({x: loadedData.x[index], y: loadedData.y[index]});
        });        

        frechet= frechetDistance(frechetSim, frechetLoad);
        similarity= shapeSimilarity(frechetSim, frechetLoad);
        comparestring="Frechet Distance: " +Math.floor(frechet*1000)/1000  + ". Plot Similarity: "+Math.floor(similarity*1000)/1000 ;
    }
    return(
        <div className="plot_container_pane">
            <Plot className="plot"
                id="cv_plot"
                name="cv_plot"
                data = {[
                    cv_data,
                    loadedData
                ]}
                layout = {new_layout}
                config = {config}
                onUpdate={props.update}
            />
            {comparestring}
        </div>
    );
};

export default CVPlot;