import React from 'react';
import './styles.css';
import {useDispatch} from 'react-redux';
import UIActions from '../../actions/UIActions';
import FormModuleSpeciesInput from './ui/form/FormModuleSpeciesInput';

function ModifySpeciesPanel() {
    // Store Interfaces
    const dispatch = useDispatch();

    // Called when the cancel button is clicked - leave login page
    function cancelEvent() {
        dispatch(UIActions.pageStateChanged("view_simulation"));
    };

    return(
        <div className="modify_species_panel">
            <h2>Species Database Access</h2>
                <FormModuleSpeciesInput/>
            <button className="cancel_button" onClick={cancelEvent}>Cancel</button>
        </div>
    );
};

export default ModifySpeciesPanel;