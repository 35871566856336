import {types} from './ActionTypes.js';
/* These actions are used for interaction with form modules */

/* Action called when drop-down option is selected */
const optionSelected = (formName, selectedOption) => {
    return {
        type: types.OPTION_SELECTED,
        form: formName,
        selected: selectedOption
    };
};

/* Action called when a tab is selected */
const tabSelected = (formName, tabName, selectedTab) => {
    return {
        type: types.TAB_SELECTED,
        form: formName,
        tab: tabName,
        selected: selectedTab
    };
};

/* Action called when list item is toggled (enabled/disabled) */
const itemToggled = (formName, itemName) => {
    return {
        type: types.ITEM_TOGGLED,
        form: formName,
        item: itemName
    };
};

/* Action called when text field is changed */
const textFieldChanged = (formName, fieldName, newText) => {
    return {
        type: types.TEXT_FIELD_CHANGED,
        form: formName,
        field: fieldName,
        text: newText
    };
};

/* Action called when number field is changed */
const numberFieldChanged = (formName, fieldName, newValue) => {
    return {
        type: types.NUMBER_FIELD_CHANGED,
        form: formName,
        field: fieldName,
        value: newValue
    };
};

/* Action called when number field is halved */
const numberFieldHalved = (formName, fieldName) => {
    return {
        type: types.NUMBER_FIELD_HALVED,
        form: formName,
        field: fieldName
    };
};

/* Action called when number field is doubled */
const numberFieldDoubled = (formName, fieldName) => {
    return {
        type: types.NUMBER_FIELD_DOUBLED,
        form: formName,
        field: fieldName
    };
};

/* Action called when new item added to list */
const listItemAdded = (formName) => {
    return {
        type: types.LIST_ITEM_ADDED,
        form: formName
    };
};

/* Action called when list item removed */
const listItemRemoved = (formName, itemIndex) => {
    return {
        type: types.LIST_ITEM_REMOVED,
        form: formName,
        index: itemIndex
    };
};
/* Action called when species need validation */
const validateSpeciesInputs = (formName, max, min) => {
    return {
        type: types.VALIDATE_SPECIES_INPUTS,
        form: formName,
        maxVolt: max,
        minVolt: min
    };
};

/* Action called when one adds or modifies a species */
const addOrModifySpecies = (formName, params, fullURL) => {
    return {
        type: types.ADD_MODIFY_SPECIES,
        form: formName,
        params: params,
        URL: fullURL
    };
};

/* Action called when one adds or modifies a species */
const removeSpecies = (formName, params, fullURL, name) => {
    return {
        type: types.REMOVE_SPECIES,
        form: formName,
        params: params,
        URL: fullURL,
        speciesName: name
    };
};

const FormActions = {
    optionSelected,
    tabSelected,
    itemToggled,
    textFieldChanged,
    numberFieldChanged,
    numberFieldHalved,
    numberFieldDoubled,
    listItemAdded,
    listItemRemoved,
    validateSpeciesInputs,
    addOrModifySpecies,
    removeSpecies
};

export default FormActions;