import React from 'react';
import './styles.css';
import LoadSimulationForm from './form/LoadSimulationForm';

function ControlWindowLoad() {
    // DOM Element Initialization
    var load_simulation_form = <LoadSimulationForm />

    return(
        <div className="control_window_view" id="control_window_load">
            {load_simulation_form}
        </div>
    );
}; 

export default ControlWindowLoad;