import {types} from '../actions/ActionTypes.js';


/* The default state for the species Modification form module */
const defaultState = {
    species_option_selected: 0,
    validated_inputs: false,
    resultStateAdd: false,
    resultStateRemove: false,
    speciesinput_new_species:"",
    speciesinput_species_to_remove:"",
    speciesinput_e_0:0.25,
    speciesinput_d_o:0.00003,
    speciesinput_d_r:0.00003,
    speciesinput_n:1,
    speciesinput_k_0:0.01,
    speciesinput_alpha:0.5,
    speciesListed:0,

    speciesinput_species_message: "",
    speciesinput_speciesadd_message: "",
    speciesinput_e_0_message: "",
    speciesinput_d_o_message: "",
    speciesinput_d_r_message: "",
    speciesinput_n_message: "",
    speciesinput_k_0_message: "",
    speciesinput_alpha_message: "",
    speciesinput_remove_message: "",
};


/* The reducer for the species modification form module */
const SpeciesModReducer = (state = defaultState, action) => {
    //checks that speciesinput is the form name, then initializes stateName with speciesinput_ so that 
    if(action.form === 'speciesinput') {
        var fullURL="";
        var params="";
        var stateName = "speciesinput_";
        switch(action.type) {
            //updates option selected fields
            case types.OPTION_SELECTED:
                //console.log(action.selected);
                return {...state, species_option_selected: action.selected};
            //updates text field based on statename and action.field
            case types.TEXT_FIELD_CHANGED:
                stateName += action.field;
                var speciesName= action.text;
                return {...state, [stateName]: speciesName};
                //updates number field value
            case types.NUMBER_FIELD_CHANGED:
                stateName += action.field;
                var numberFieldVal= action.value;
                
                return {...state, [stateName]: numberFieldVal};
            case types.VALIDATE_SPECIES_INPUTS:
                //is true as long as all checks pass
                var isValid = true;
                var min_voltage_allowed= action.minVolt;
                var max_voltage_allowed= action.maxVolt;
                var species_entry_regex = /(^([0-9a-zA-Z][0-9a-zA-Z_ -]*[0-9a-zA-Z]|[0-9a-zA-Z])$)/;
                // the first option matches anything starting and ending with a letter or number character (letters, numbers, underscores)
                // with 0 or more letters, numbers, underscores, dashes or spaces in between
                // the second option matches any single character input or a "word" character (since the first part needs a separate beginning and ending character)

                var nonnegative_integer_regex = /(^([0-9]+|([0-9]+e[0-9]+))$)/;
                //has to contain at least 1 or more digits and nothing else
                //OR digits before and after a single exponent (the exponent cannot be negative since that would not be an integer)


                //reset all error messages
                    
                    state.speciesinput_species_message="";

                    state.speciesinput_e_0_message="";
                    state.speciesinput_d_o_message="";
                    state.speciesinput_d_r_message="";
                    state.speciesinput_n_message="";
                    state.speciesinput_k_0_message= "";
                    state.speciesinput_alpha_message= "";
                

                //test that species matches the regex defined above
                if(!species_entry_regex.test(state.speciesinput_new_species)){
                    state.speciesinput_species_message="SPECIES CAN CONTAIN ONLY: NUMBERS, LETTERS, UNDERSCORES, DASHES, AND SPACES AND MUST BEGIN AND END WITH A NUMBER OR LETTER";

                    isValid = false;
                }
 
                if(state.speciesinput_new_species==="Ferrocene" || state.speciesinput_new_species==="Ruthenium"){
                        state.speciesinput_species_message="You do not have permission to modify this species"
                        isValid=false;
                }
                var msg = "";
                if(state.speciesinput_e_0<min_voltage_allowed){
                    msg = "MUST BE AT LEAST -" + max_voltage_allowed;
                        
                    state.speciesinput_e_0_message= msg;
                    
                    isValid = false;
                } else if (state.speciesinput_e_0>max_voltage_allowed){
                    msg = "MUST BE NO MORE THAN " + max_voltage_allowed;
                        
                    state.speciesinput_e_0_message= msg;
                    
                    isValid = false;
                } else if (state.speciesinput_e_0 === 0){
                    msg = "CANNOT BE 0";
                        
                    state.speciesinput_e_0_message= msg;
                    
                    isValid = false;
                }

                //test D_O
                if(state.speciesinput_d_o<=0){
                    msg = "MUST BE POSITIVE";
                        
                    state.speciesinput_d_o_message= msg;
                    
                    isValid = false;
                }
                
                //test D_R
                if(state.speciesinput_d_r<=0){
                    msg = "MUST BE POSITIVE";
                        
                    state.speciesinput_d_r_message= msg;
                    
                    isValid = false;
                }

                //test k_0
                if(state.speciesinput_k_0<=0){
                    msg = "MUST BE POSITIVE";
                    state.speciesinput_k_0_message= msg;
                    
                    isValid = false;
                }

                //test n is a positive integer
                if(state.speciesinput_n<=0){
                    msg = "MUST BE POSITIVE";
                        
                    state.speciesinput_n_message= msg;
                    
                    isValid = false;
                } else if(!nonnegative_integer_regex.test(state.speciesinput_n)){
                    msg = "MUST BE AN INTEGER";
                        
                    state.speciesinput_n_message= msg;
                    
                    isValid = false;
                }

                //test alpha
                if(state.speciesinput_alpha<=0){
                    msg = "MUST BE POSITIVE";
                        
                    state.speciesinput_alpha_message= msg;
                    
                    isValid = false;
                } else if(state.speciesinput_alpha>1){
                    msg = "MUST BE NO MORE THAN ONE";
                        
                    state.speciesinput_alpha_message= msg;
                    
                    isValid = false;
                }

                //if all tests passed, isValid will still be true
                //if any failed, it will have been set to false
                    state.validated_inputs= isValid;
                return {...state, validated_inputs: isValid};
            case types.ADD_MODIFY_SPECIES:
                //sets up all variables needed and sends request to backend for adding/modifying species
                fullURL= action.URL;
                params= action.params;
                var resultStateAdd= defaultState.resultStateAdd;
                var speciesMessage="The species "+ state.speciesinput_new_species + " was successfully added";

                //opens a new HTTP request
                var speciesAdd = new XMLHttpRequest();

                //connects to the URL to get data
                speciesAdd.open('POST', fullURL, true);
                speciesAdd.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                speciesAdd.onreadystatechange = function(e) {
                    if(speciesAdd.readyState === XMLHttpRequest.DONE && speciesAdd.status===200){
                    //refresh the list of species and change to a confirmation page
                    resultStateAdd= true;

                    }
                    else{
                    resultStateAdd= false;
                    speciesMessage="The species "+ state.speciesinput_new_species +" failed to be added";

                    }
                };
                speciesAdd.send(params);
                return {...state, resultStateAdd: resultStateAdd, speciesinput_speciesadd_message: speciesMessage, speciesinput_remove_message: "", species_option_selected: defaultState.species_option_selected};
            case types.REMOVE_SPECIES:
                //goes through the steps to send an httprequest to remove a species
                fullURL= action.URL;
                params= action.params;
                var removed_species= action.speciesName;
                var resultStateRemove= defaultState.resultStateRemove;
                var isValidRemove=true;
                var removeMessage= "The species "+ removed_species +" was successfully removed";
                if(removed_species==="Ferrocene" || removed_species==="Ruthenium"){
                    removeMessage="You do not have permission to remove this species"
                    isValidRemove=false;
                }   
            //opens a new HTTP request
                if(isValidRemove){
                    var speciesRemove = new XMLHttpRequest();

                    //connects to the URL to get data
                    speciesRemove.open('POST', fullURL, true);
                    speciesRemove.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                    speciesRemove.onreadystatechange = function(e) {
                        if(speciesRemove.readyState === XMLHttpRequest.DONE && speciesRemove.status===200){
                        //refresh the list of species and change to a confirmation page
                            resultStateRemove= true;
                        }
                        else{
                            resultStateRemove=false;
                            removeMessage="The Species "+ removed_species  + " Failed to be removed";
                        }
                    };
                    speciesRemove.send(params);
                }
                return {...state, resultStateRemove: resultStateRemove, speciesinput_remove_message: removeMessage, speciesinput_speciesadd_message: "", species_option_selected: defaultState.species_option_selected};                
            default:
                return state;
        };
    } 
    else {
        return state;
    };
};

export default SpeciesModReducer;