import React from 'react';
//import './SpeciesInput.css';
import Urls from '../../../../actions/Urls';
import {useSelector, useDispatch} from 'react-redux';
import FormActions from '../../../../actions/FormActions';
import DatabaseActions from '../../../../actions/DatabaseActions';
import './styles.css';


function FormModuleSpeciesInput() {

    // Store Interfaces
    const waveState = useSelector(state => state.form.waveform);
    const httpState = useSelector(state => state.http);
    const state = useSelector(state => state.form.speciesmod);
    //define max and min voltages allowed
    var max_voltage_allowed =  waveState.waveform_maxVoltage;
    var min_voltage_allowed =  waveState.waveform_minVoltage;
    const formName = 'speciesinput';

    //dispatch initialization
    const dispatch = useDispatch();

    //Called when the option is selected in a select input.
    const optionSelected = event => {
        dispatch(FormActions.optionSelected(formName, event.target.value));
    };

    // Called when a number field is changed
    const numberFieldChangedEvent = event => {
        dispatch(FormActions.numberFieldChanged(formName, event.target.name, event.target.value));
    };
    // Called when a text field is changed
    const textFieldChangedEvent = event => {
        dispatch(FormActions.textFieldChanged(formName, event.target.name, event.target.value));
    };
    //this function dispatches the corresponding form action for validating inputs
    function validateAllInputs(){
        dispatch(FormActions.validateSpeciesInputs(formName, max_voltage_allowed, min_voltage_allowed));
    };
    //this function dispatches the addOrModifySpecies form action, after setting params from the proper locations. it also calls validateAllInputs() to ensure these inputs are correct.
    //NOTE: Yes, the calls to refreshSpeciesList() and collectSpeciesOptions() appear superfluous. Trust me, though, they are needed like this or else the UI does not properly detect/display changes.
    const addOrModifySpecies = event => {
        refreshSpeciesList();
        validateAllInputs();
        var params = "type=addSpecies&new_species="+ state.speciesinput_new_species + "&e_0=" + state.speciesinput_e_0 + "&d_o=" + state.speciesinput_d_o + "&d_r=" + state.speciesinput_d_r +"&n=" + state.speciesinput_n +"&k_0=" + state.speciesinput_k_0 + "&alpha=" +state.speciesinput_alpha + "&username=" + httpState.session_username;
        var fullURL = Urls.fullUrl;
        if(state.validated_inputs){
            dispatch(FormActions.addOrModifySpecies(formName, params, fullURL));
        }
        refreshSpeciesList();

    };
    //this is the function which dispatches the removeSpecies formaction, which calls for the name of the species to remove from the selected list index, and begins the process of removing a species
    //NOTE: Yes, the calls to refreshSpeciesList() and collectSpeciesOptions() appear superfluous. Trust me, though, they are needed like this or else the UI does not properly detect/display changes.
    const removeSpecies = event => {
        refreshSpeciesList();
        var species_to_remove= httpState.speciesData[state.species_option_selected].name;
        var params = "type=removeSpecies&species_to_remove="+ species_to_remove+ "&username=" + httpState.session_username;
        var fullURL = Urls.fullUrl;
        dispatch(FormActions.removeSpecies(formName, params, fullURL, species_to_remove));
        dispatch(FormActions.optionSelected('species', 0));
        refreshSpeciesList();

    };
    //this function collects the species options for the lists.
    function collectSpeciesOptions() {
        if(httpState.speciesDatabaseSynced) {
           refreshSpeciesList();
        }
        return httpState.speciesData.map((item, index) =>
        <option key={index.toString()} value={index.toString()} title={item.text}>
            {item.name}
        </option>
        );
    };
    //this function forces a query to the database for all the species to list, which is vital for updating these lists once a species is added, modified, or removed
    function refreshSpeciesList(){
            dispatch(DatabaseActions.querySecureSpeciesDatabase(httpState.session_username, httpState.sessionLoggedIn));
    };

    //these variables provide both the select bar and species_options which go into the select bar.
    var species_options = collectSpeciesOptions();
    var species_select = 
        <select className="select_species" name="speciesSelect" onChange={optionSelected} value={state.species_option_selected}>
            {species_options}
        </select>;


    //This file returns the form for species input, modification, and removal. This is the corresponding JSX code with inputs and buttons and text.
    return (
        <React.Fragment>
        <div className="form_module" id="form_module_modifyspecies">
                <h1>Enter Parameters <br></br> for Species to Add or Modify</h1>
                     <label>
                        Species Name 
                        (inputting the name of an existing species will modify that species)

                        <input className="text_input" name="new_species" value={state.speciesinput_new_species} onChange={textFieldChangedEvent} ></input>
                        <span className="input_feedback" id="input_feedback">{state.speciesinput_species_message}</span>                 

                    </label>
                     <label>
                        Standard Potential "E_0" (V)

                        <input className="num_input" type="number" min={min_voltage_allowed} max={max_voltage_allowed} step="0.05" value={state.speciesinput_e_0}  onChange={numberFieldChangedEvent} name="e_0"></input>
                        <span className="input_feedback" id="input_feedback">{state.speciesinput_e_0_message}</span>

                    </label>
                     <label>
                        Oxidating Diffusion Coefficient "D_O" (cm^2/s)

                        <input className="num_input" type="number" min="0" step="0.00001" value={state.speciesinput_d_o}  onChange={numberFieldChangedEvent} name="d_o"></input>
                        <span className="input_feedback" id="input_feedback">{state.speciesinput_d_o_message}</span>

                    </label>
                     <label>
                        Reducing Diffusion Coefficient "D_R" (cm^2/s)

                        <input className="num_input" type="number" min="0" step="0.00001" value={state.speciesinput_d_r}  onChange={numberFieldChangedEvent} name="d_r"></input>
                        <span className="input_feedback" id="input_feedback">{state.speciesinput_d_r_message}</span>

                    </label>
                     <label>
                        Number of Electrons Transferred "n" (electron(s))

                        <input className="num_input" type="number" min="1" step="1" value={state.speciesinput_n}  onChange={numberFieldChangedEvent} name="n"></input>
                        <span className="input_feedback" id="input_feedback">{state.speciesinput_n_message}</span>

                    </label>
                     <label>
                        Electrochemical Rate Constant "k_0" (cm/s)

                        <input className="num_input" type="number" min="0" step="0.005" value={state.speciesinput_k_0}  onChange={numberFieldChangedEvent} name="k_0"></input>
                        <span className="input_feedback" id="input_feedback">{state.speciesinput_k_0_message}</span>

                    </label>
                     <label>
                        Charge-Transfer Coefficient "alpha"

                        <input className="num_input" type="number" min="0" max="1" step="0.05" value={state.speciesinput_alpha}  onChange={numberFieldChangedEvent} name="alpha"></input>
                        <span className="input_feedback" id="input_feedback">{state.speciesinput_alpha_message}</span>

                    </label>
            <button className="submit" onClick={addOrModifySpecies}>Add/Modify Species</button><br/>
                <span className="species_success" name= "speciesadd_message">{state.speciesinput_speciesadd_message}</span>
                   <h1> Select Species to Remove</h1>
                   <label id="remove">

                    {species_select}
                    </label>
                    <button className="submit" onClick={removeSpecies} name="removespecies">Remove</button><br/>
                    <span className="input_feedback" name="remove_message">{state.speciesinput_remove_message}</span>


         
                </div>    

                </React.Fragment>         
    );
}


export default FormModuleSpeciesInput;
