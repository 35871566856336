import React from 'react';
import './styles.css';
import PlotContainerGrid from './ui/display/PlotContainerGrid';
import CycleSelector from './ui/input/CycleSelector';
import {useSelector} from 'react-redux';

function PlotWindowPanel() {
    const state = useSelector(state => state);
    //subsequent code determines whether the Cycle Selector component should be visible, by only showing it once a simulation is completed (Same logic as state.ui.simulationHasRan, so we reuse that value)
    var cycle_selector_view = null;
    switch(state.ui.simulationHasRan) {
        case true:
            cycle_selector_view = <CycleSelector />;
            break;
        default:
            cycle_selector_view = null;
            break;
    }; 
    //The Plot Window Panel Consists of the PlotContainerGrid and the Cycle Selector
    return(
        <div className="plot_window_panel">
            <h2>Plots</h2>
            <PlotContainerGrid />
            {/* Container for displaying plots in a grid-like view */}
            {cycle_selector_view}
        </div>
    );
};

export default PlotWindowPanel;