import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import UIActions from '../../actions/UIActions';
import DatabaseActions from '../../actions/DatabaseActions';
import FormActions from '../../actions/FormActions';

function LoginPanel() {
    // Store Interfaces
    const state = useSelector(state => state.http);
    const UIstate= useSelector(state => state.ui);
    const dispatch = useDispatch();

    // Called when the login button is clicked - authenticate user
    function loginEvent() {
        dispatch(DatabaseActions.authenticateUser(state.session_username, state.session_password, UIstate.pageState));
    };

    // Called when the cancel button is clicked - leave login page
    function cancelEvent() {
        dispatch(UIActions.pageStateChanged("view_simulation"));
    };

    // Called when a text field is changed
    const textFieldChangedEvent = event => {
        dispatch(FormActions.textFieldChanged("session", event.target.name, event.target.value));
    };
    //called when the show password buttin is changed. Searches for the element with id passwordInput, and then, based on state.sessionHidePassword, changes the input type.
    const showPasswordEvent = event => {
        dispatch(DatabaseActions.showPassword());
        var x = document.getElementById("passwordInput");
        if(state.sessionHidePassword){
            x.type = "text";
        }
        else{
            x.type = "password";
        }

    };


    // DOM Element Initialization
    // Text shown when username/password sent is invalid
    var invalidLoginMessage = null;
    if(state.sessionInvalid) {
        invalidLoginMessage = <h4 className="invalid_message">Invalid username/password!</h4>;
    }

    return(
        <div className="login_panel">
            <h2>Log In to Species Modification Panel</h2>
            {invalidLoginMessage}
            <label>
                Username:
                <input type="text" className="input_username" name="username" value={state.session_username} onChange={textFieldChangedEvent} />
            </label>
            <label>
                Password:
                <input type="password" className="input_password" id="passwordInput" name="password" value={state.session_password} onChange={textFieldChangedEvent} />
            </label>
            <label>
                Show Password:
                <input type="checkbox" className="input_show_password" name="showPassword" value={!state.sessionHidePassword} checked={!state.sessionHidePassword} onChange={showPasswordEvent}/>
            </label>
            <button className="login_button" onClick={loginEvent}>Login</button>
            <button className="cancel_button" onClick={cancelEvent}>Cancel</button>
        </div>
    );
};

export default LoginPanel;