import React from 'react';
import Plot from 'react-plotly.js';
import './styles.css';
import {useSelector} from 'react-redux';

function ConcPlot(props) {
    // Store Interfaces
    const state = useSelector(state => state.plotData);
    const UIstate = useSelector(state => state.ui);

    // Default layout properties
    var config = state.default_config;
    var layout = state.default_layout;
    var slider = state.default_slider_format.time_slider;
    var slider_loaded = state.default_slider_format.time_slider;
    var simLength= state.cvtPlotDataFull.vt_data.x.length;
    var loadLength= state.cvtPlotDataLoaded.vt_data.x.length;
    var endtimeSim= (state.cvtPlotDataFull.vt_data.x[simLength-1]);
    var endtimeLoad=(state.cvtPlotDataLoaded.vt_data.x[loadLength-1]);

    var new_layout = { ...layout,
        title: { ...layout.title, text: 'Concentration vs Distance' },
        xaxis: { ...layout.xaxis, title: { ...layout.xaxis.title, text: 'Distance From Electrode (cm)' } },
        yaxis: { ...layout.yaxis, title: { ...layout.yaxis.title, text: 'Concentration' }, range: [0, 1], fixedrange: true },
        sliders: [{ ...slider, steps: state.concPlotData.conc_steps , name: "conc_slider"},{ ...slider_loaded, steps: state.concPlotDataLoaded.conc_steps , name: "conc_slider_loaded"}],
        automargin: true,
    };

    new_layout.sliders[0].visible=UIstate.simulationHasRan;
    new_layout.sliders[1].visible=state.compare_data;
    if(new_layout.sliders[0].visible && new_layout.sliders[1].visible){
        if(endtimeSim>= endtimeLoad || UIstate.experimentalLoaded){
            new_layout.sliders[0].visible=true;
            new_layout.sliders[1].visible=false;
        }
        else{
            new_layout.sliders[1].visible=true;
            new_layout.sliders[0].visible=false;
        }

        //new_layout.sliders[0].visible=false;
    }
    var isLoadVisible=state.compare_data;
    var isSimVisible=UIstate.simulationHasRan;
    /*if(UIstate.experimentalLoaded){
        new_layout.sliders[0].visible=true;
        new_layout.sliders[1].visible=false;
        isLoadVisible=false;
        if(!UIstate.simulationHasRan){
            new_layout.sliders[0].visible=false;

        }
    }*/
    var dist_arr = state.concPlotData.conc_x;
    var frames = state.concPlotData.conc_frames;
    return(
        <div className="plot_container_pane">
            <Plot className="plot"
                id="conc_plot"
                name="conc_plot"
                graphDiv="concentration_vs_distance_vs_time"
                data = {[
                    {
                        name: 'Conc. Oxidized',
                        type: 'scatter',
                        x: dist_arr,
                        y: frames[0].data[0].y,
                        mode: 'lines',
                        visible: isSimVisible,
                    }, {
                        name: 'Conc. Reduced',
                        type: 'scatter',
                        x: dist_arr,
                        y: frames[0].data[1].y,
                        mode: 'lines',
                        visible: isSimVisible,
                    },
                    {
                        name: 'Conc. Oxi. Load',
                        type: 'scatter',
                        x: state.concPlotDataLoaded.conc_x,
                        y: state.concPlotDataLoaded.conc_frames[0].data[0].y,
                        mode: 'lines',
                        visible: isLoadVisible,
                    }, {
                        name: 'Conc. Red. Load',
                        type: 'scatter',
                        x: state.concPlotDataLoaded.conc_x,
                        y: state.concPlotDataLoaded.conc_frames[0].data[1].y,
                        mode: 'lines',
                        visible: isLoadVisible,

                    },
                ]}
                layout = {new_layout}
                config = {config}
                onUpdate={props.update}
                onSliderChange={(e) => props.sliderMoved(e, "conc_plot", "none")}
            />
            <div id="conc_plot_pane"><label className="voltage_label">Voltage (mV): </label></div>
        </div>
    );
};

export default ConcPlot;