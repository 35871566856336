import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import UIActions from '../../../../actions/UIActions';
import SimulationActions from '../../../../actions/SimulationActions';

function LoadSimulationButton() {

    // Store Interfaces
    const state = useSelector(state => state);
    const httpState = useSelector(state => state.http);
    const dispatch = useDispatch();

    // Called when the button is clicked
    const buttonClickedEvent = event => {
        event.preventDefault();
        document.getElementById('load_input').click();
    };

    // Called when a file is selected
    const fileLoadedEvent = event => {
        loadSimulationData(event.target.value, event.target.files[0]);
        // Setting the value to null allows the same file to be loaded again immediately
        event.target.value = null;
    };

    // Make sure all these parameters exist when loading file
    const check_params_exist = 
        ["species_inputs", "waveform_inputs", "plot_data"];
    //parses the raw voltage and current data from an uploaded csv
    //expects an array containing comma-delimited voltage and current data as well as an object for the parsed data
    //the object MUST contain a valid scan rate
    function parseRawData(rawDataArray, uploadedDataJSON, fileName) {
        //try to get the sample interval if it's missing
        
        var msg, err_msg;
        if(uploadedDataJSON.voltage_sample_interval === undefined || uploadedDataJSON.voltage_sample_interval === ""){
            try {
                //votlage should be stored as the first comma-delimited element
                //we can calculate the sample rate by finding the difference in the first two voltage readings
                var voltage_0 = rawDataArray[0].split(', ')[0];
                var voltage_1 = rawDataArray[1].split(', ')[0];
                uploadedDataJSON.voltage_sample_interval = Math.abs(voltage_1 - voltage_0);
                msg = "Sucessfully extracted voltage sample interval as " + uploadedDataJSON.voltage_sample_interval;
                //console.log(msg);
            } catch(e) {
                err_msg="Error finding Voltage Sample Interval from file "+fileName+" provided.";
                console.error(err_msg);
                //this error is unrecoverable, so abort upload by returning
                return;
            }
        }

        var time = 0;
        var voltageStep = uploadedDataJSON.voltage_sample_interval;
        var scanRate = uploadedDataJSON.scan_rate;
        var timeStep = voltageStep / scanRate;

        for(var i=0; i<rawDataArray.length; i++){
            try {
                var lineContents = rawDataArray[i].split(', ');
                //push the current time and advance it for the next iteration
                uploadedDataJSON.time_data.push(time);
                // console.log(time + ", " + uploadedDataJSON.time_data[i]);
                time += timeStep;

                //find the voltage and current data and push them onto the respective arrays
                uploadedDataJSON.voltage_data.push(lineContents[0]);
                uploadedDataJSON.current_data.push(lineContents[1]*1e6);
                // console.log(uploadedDataJSON.voltage_data[i] + ", " + uploadedDataJSON.current_data[i]);
            } catch(e){
                err_msg="Error parsing raw CSV voltage/current data in line " + i + " of "+fileName+"\nUpload aborted.";
                console.error(err_msg);

                //this error is unrecoverable, so abort upload by returning
                return;
            }
        }
        if(uploadedDataJSON.voltage_max === undefined || uploadedDataJSON.voltage_max === ""){
            try {
                uploadedDataJSON.voltage_max = uploadedDataJSON.voltage_data.reduce(function(a,b){
                    return Math.max(a,b);
                });
                msg = "Sucessfully extracted max voltage as " + uploadedDataJSON.voltage_max;
                //console.log(msg);
            } catch(e) {
                err_msg="Error finding Max Voltage from file "+fileName+" provided.";
                console.error(err_msg);
                //this error is unrecoverable, so abort upload by returning
                return;
            }
        }
        if(uploadedDataJSON.voltage_min === undefined || uploadedDataJSON.voltage_min === ""){
            try {
                uploadedDataJSON.voltage_min = uploadedDataJSON.voltage_data.reduce(function(a,b){
                    return Math.min(a,b);
                });
                msg = "Sucessfully extracted min voltage as " + uploadedDataJSON.voltage_min;
                //console.log(msg);
            } catch(e) {
                err_msg="Error finding Min Voltage from file "+fileName+" provided.";
                console.error(err_msg);

                //this error is unrecoverable, so abort upload by returning
                return;
            }
        }
        //restrict the data to one cycle starting with the average of min and max and increasing first
        //the input will either end at the max or min voltage
        var array_length = uploadedDataJSON.time_data.length;
        var cycle_length = Math.round((uploadedDataJSON.voltage_max - uploadedDataJSON.voltage_min) / voltageStep * 2);
        if(array_length < cycle_length + 1){
            console.error("Data array too short, too few voltage points in file: " + fileName);
            return;
        }

        var split_point = 0;
        var beginning_of_last_cycle = Math.round(array_length-cycle_length);
        if(uploadedDataJSON.voltage_max === uploadedDataJSON.voltage_data[array_length-1]){
            //the last cycle starts at the max, goes to the min, and then back to the max
            //to get it to start in the middle and go to max, then mid, then middle,
            //the last quarter cycle will be the new beginning and the three-quarters before that will be the new end of the cycle
            split_point = Math.round(array_length-cycle_length/4-1); //subtract 1 to account for endpoints
        } else{
            //the last cycle starts at the min, goes to the max, and then back to the min
            //to get it to start in the middle and go to max, then mid, then middle,
            //the last three-quarters cycle will be the new beginning and the quarter cycle before that will be the new end of the cycle
            split_point = Math.round(array_length-cycle_length*3/4-1); //subtract 1 to account for endpoints
        }
        // move the part from after the split point before the rest of the last cycle
        uploadedDataJSON.voltage_data = uploadedDataJSON.voltage_data.slice(split_point).concat(uploadedDataJSON.voltage_data.slice(beginning_of_last_cycle,split_point));
        uploadedDataJSON.current_data = uploadedDataJSON.current_data.slice(split_point).concat(uploadedDataJSON.current_data.slice(beginning_of_last_cycle,split_point));
        // we want the time data to match the fourth cycle of the simulation
        uploadedDataJSON.time_data = uploadedDataJSON.time_data.slice(0,cycle_length); //first truncate it to 1 cycle
        var time_per_three_cycles = 3 * cycle_length * timeStep;
        uploadedDataJSON.time_data_full = uploadedDataJSON.time_data.map(x => x + time_per_three_cycles); //then add 3 cycles worth of time to that cycle
        
        dispatch(SimulationActions.experimentalDataLoaded(uploadedDataJSON));
        dispatch(UIActions.simulationLoaded(true));

        //this.props.setComparePlotData(compare_plot_data);
    ;}        

    // Called when a file is loaded
    function loadSimulationData(value, file) {
        var reader = new FileReader();
        if(file !== undefined) {
            var lastModified = file.lastModified;
            if(value !== state.ui.lastFileValue || lastModified > state.ui.lastFileModified) {
                dispatch(UIActions.newFileLoaded(value, lastModified));
                if(file.type==="application/json") {         
                    reader.onload = function(event) {
                        var data = JSON.parse(event.target.result);

                        if(!check_params_exist.every(param => Object.keys(data).includes(param))) {
                            dispatch(UIActions.inputFileInvalid());
                            //console.log("Invalid file");
                            return;
                        };

                        var species_loaded = data.species_inputs;
                        dispatch(SimulationActions.speciesDataLoaded(species_loaded));
                        var waveform_loaded = data.waveform_inputs;
                        dispatch(SimulationActions.waveformDataLoaded(waveform_loaded));
                        var cv_plot_loaded = data.plot_data.cv_plot;
                        dispatch(SimulationActions.cvPlotDataLoaded(cv_plot_loaded));
                        var cvt_plot_loaded = data.plot_data.cvt_plot;
                        dispatch(SimulationActions.cvtPlotDataLoaded(cvt_plot_loaded));
                        var conc_plot_loaded = data.plot_data.conc_plot;
                        dispatch(SimulationActions.concPlotDataLoaded(conc_plot_loaded));
                        var heatmap_plot_loaded = data.plot_data.heatmap_plot;
                        dispatch(SimulationActions.heatmapPlotDataLoaded(heatmap_plot_loaded));


                        dispatch(UIActions.simulationLoaded(false));

                    };
                    reader.readAsText(file);
                }
                else if(file.type==="application/vnd.ms-excel" || file.type==="text/plain"){
                    reader.onload = function(event) {
                    //parse the uploaded file
                        var contents = event.target.result; 
                        //the following lines are taken from http://techslides.com/convert-csv-to-json-in-javascript and modified to work with our program.                       
                        var lines=contents.split("\n");
                        // var result = [];
                        // var headers=lines[0].split(",");
        
                        var uploaded_data = {
                            header: "N/A",
                            voltage_init: "N/A",
                            voltage_max: "",
                            voltage_min: "",
                            init_PN: "N/A",
                            scan_rate: "",
                            segment: "N/A",
                            voltage_sample_interval: "",
                            quiet_time: "N/A",
                            sensitivity: "N/A",
                            voltage_data: [],
                            time_data: [],
                            current_data: []
                        }
        
                        var err_msg, fileName;
                        fileName= file.name;

                        for(var i=1;i<lines.length;i++){
                            // var obj = {};
                            // var currentline=lines[i].split(",");
                            // // for(var j=0;j<headers.length;j++){
                            // //     obj[headers[j]] = currentline[j];
                            // // }
                            // result.push(obj);
                            var currentLine = lines[i];
                            if (currentLine.startsWith("Header:")){
                                uploaded_data.header = currentLine.split(": ")[1]
                                //console.log("Header = "+ uploaded_data.header);
                            }
                            else if (currentLine.startsWith("Init E")){
                                uploaded_data.voltage_init = currentLine.split("= ")[1]
                                //console.log("Initial V = "+ uploaded_data.voltage_init);
                            }
                            else if (currentLine.startsWith("High E")){
                                uploaded_data.voltage_max = currentLine.split("= ")[1]
                                //console.log("Max V = "+ uploaded_data.voltage_max);
                            }
                            else if (currentLine.startsWith("Low E")){
                                uploaded_data.voltage_min = currentLine.split("= ")[1]
                                //console.log("Min V = "+ uploaded_data.voltage_min);
                            }
        
                            else if (currentLine.startsWith("Init P")){
                                uploaded_data.init_PN = currentLine.split("= ")[1]
                                //console.log("Init P/N = "+ uploaded_data.init_PN);
                            }
                            else if (currentLine.startsWith("Segment =")){
                                uploaded_data.segment = currentLine.split("= ")[1]
                                //console.log("Segment = "+ uploaded_data.segment);
                            }
                            else if (currentLine.startsWith("Scan Rate")){
                                uploaded_data.scan_rate = currentLine.split("= ")[1]
                                //console.log("Scan Rate = "+ uploaded_data.scan_rate);
                            }
                            else if (currentLine.startsWith("Sample Interval")){
                                uploaded_data.voltage_sample_interval = currentLine.split("= ")[1]
                                //console.log("Interval = "+ uploaded_data.voltage_sample_interval);
                            }
                            else if (currentLine.startsWith("Quiet Time")){
                                uploaded_data.quiet_time = currentLine.split("= ")[1]
                                //console.log("Quiet Time (sec) = "+ uploaded_data.quiet_time);
                            }
                            else if (currentLine.startsWith("Sensitivity")){
                                uploaded_data.sensitivity = currentLine.split("= ")[1]
                                //console.log("Sensitivity (A/V) = "+ uploaded_data.sensitivity);
                            }
                            //right before the raw data is a line with Potential/V, i1/A, i2/A, ...
                            //this is the only place that Potential appears in the file, so we can use it to isolate the raw voltage and current data
                            //we also expect this to be at the end with exactly one blank line following the header and one blank line at the end
                            else if (currentLine.startsWith("Potential")){
                                if(lines.length<=i+2){
                                    //the file is too short to contain any data
                                    err_msg="Error!\nUploaded file "+ fileName +" is missing a necessary input:\n-Voltage and Current Data\nUpload Aborted";
                                    //since we can't recover, we will end the function, thus aborting the uploading of the data
                                    alert(err_msg);
                                    return;
                                }
        
                                if(uploaded_data.scan_rate === undefined || uploaded_data.scan_rate === ""){
                                    //scan rate is the ONLY input that cannot be extrapolated from the raw data, so we cannot proceed without it
                                    err_msg = "Error!\nUploaded file "+ fileName +" is missing a necessary input:\n-Scan Rate\nUpload Aborted";
                                    alert(err_msg);
                                    //since we can't recover, we will end the function, thus aborting the uploading of the data
                                    return;
                                } else if (uploaded_data.scan_rate <= 0){
                                    err_msg = "Error!\nUploaded file "+ fileName +" contains an invalid input:\n-Scan Rate must be positive\nUpload Aborted";

                                    alert(err_msg);
                                    //since we can't recover, we will end the function, thus aborting the uploading of the data
                                    return;
                                }
        
                                if(uploaded_data.voltage_sample_interval === undefined || uploaded_data.voltage_sample_interval === "" ){
                                    //missing sample interval
                                    //since we can extract this from the data itself, the data parser will detect that it's missing and extract the proper value
                                    console.warn("File "+ fileName +" is missing voltage sample rate... attempting to correct...");
                                } else if(uploaded_data.voltage_sample_interval <= 0 ){
                                    //invalid sample interval
                                    //since we can extract this from the data itself, we can ignore the provided value and set it to an empty string
                                    //and the data parser will detect that it's missing and extract the proper value
                                    uploaded_data.voltage_sample_interval = "";
                                    console.warn("File "+ fileName +" has invalid voltage sample rate... attempting to correct...");
                                }
                                
                                if(uploaded_data.voltage_min === undefined || uploaded_data.voltage_min === ""){
                                    //missing minimum voltage
                                    //since we can extract this from the data itself, the data parser will detect that it's missing and extract the proper value
                                    console.warn("File "+ fileName +" has missing voltage minimum... attempting to correct...");
                                    if(uploaded_data.voltage_max === undefined || uploaded_data.voltage_max === ""){
                                        //missing maximum voltage
                                        //since we can extract this from the data itself, the data parser will detect that it's missing and extract the proper value
                                        console.warn("File "+ fileName +" has missing voltage maximum... attempting to correct...");
                                    }
                                }else if(uploaded_data.voltage_max === undefined || uploaded_data.voltage_max === ""){
                                    //missing maximum voltage
                                    //since we can extract this from the data itself, the data parser will detect that it's missing and extract the proper value
                                    console.warn("File "+ fileName +" has missing voltage maximum... attempting to correct...");
                                }else if(uploaded_data.voltage_min >= uploaded_data.voltage_max ){
                                    //invalid minimum and maxium voltage
                                    //since we can extract this from the data itself, we can ignore the provided values and set them to an empty string
                                    //and the data parser will detect that they are missing and extract the proper values
                                    uploaded_data.voltage_min = "";
                                    uploaded_data.voltage_max = "";
                                    console.warn("File "+ fileName +" has invalid voltage minimum and maximum... attempting to correct...");
                                }
        
                                //if the scan rate is present, we can proceed to processing the data
        
                                //isolate the raw data and remove the blank lines and then pass it and the partial data object to the parseRawData function
                                var rawData = lines.slice(i+2,lines.length-1);
                                parseRawData(rawData, uploaded_data, fileName);

                                return;
                            }
                        }
            
                        //if we reach this point, we went through the entire file and never found the "Potential" tag to indicate voltage/current data
                        err_msg = "Error!\nUploaded file is missing a necessary input:\n-Voltage and Current Data\nUpload Aborted";
                        fileName = "Error Uploading File: " + this.state.fileName;
                        this.setState({fileName: fileName});
                        alert(err_msg);
                        this.props.setPageState('loadTab');
                    }.bind(this);
                    
                    reader.onerror = function(event) {
                        console.error("File could not be read! Code " + event.target.error.code);
                        this.props.setPageState('loadTab');
                    }
                    reader.readAsText(file);
                    return;
                }
                else{
                    alert("File uploaded is not JSON, CSV or CSV formatted TXT file");
                    return;
                } 
            }
        };
    };

    return (
        <React.Fragment>
            <button className="load_simulation_button" onClick={buttonClickedEvent} disabled={!(state.ui.pageState === 'view_simulation') || httpState.simulationRunning}>
                Load
            </button>
            <input type="file" name="loadFile" id="load_input" accept=".json, .txt, .csv" onChange={fileLoadedEvent} style={{display: "none"}} />
        </React.Fragment>
    );
};

export default LoadSimulationButton;