import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import SimulationActions from '../../../../../actions/SimulationActions';

function SimulateButton() {
    // Store Interfaces
    const state = useSelector(state => state);
    const httpState = useSelector(state => state.http);
    const waveformState = useSelector(state => state.form.waveform);
    const speciesState = useSelector(state => state.form.species);
    const dispatch = useDispatch();

    // Collects general data for simulation
    function collectSimulationParams() {
        var voltage_min = waveformState.waveform_minVoltage;
        var voltage_max = waveformState.waveform_maxVoltage;
        var start_voltage = waveformState.waveform_startVoltage;
        var scan_rate = waveformState.waveform_scanRate * 1e-3; // convert from milliVolts per second to V/s
        var cycles = waveformState.waveform_runCycles;
        var wave_type = waveformState.waveform_alias[waveformState.waveform_option_selected];
        var reduceData = waveformState.waveform_reduceData;
        var noisyData= waveformState.waveform_noisyData;

        // Return the simulation parameters
        return {
            voltage_min,
            voltage_max,
            start_voltage,
            scan_rate,
            cycles,
            wave_type,
            reduceData,
            noisyData
        };
    }

    // Collects species data for simulation
    function collectSpeciesParams() {
        var speciesList = speciesState.speciesList;
        var species_array = [];
        for(var i = 0; i < speciesList.length; i++) {
            // Check if the species should be included in the simulation
            var species = speciesList[i];
            if(species.species_enabled === true) {
                var name = httpState.speciesData[((species.species_option_selected>=httpState.speciesData.length)? 0 : species.species_option_selected)].name;
                var concentration = species.species_concentration;
                var percent_oxidized = species.species_percentOxidized;
                var fraction_reduced = (100 - percent_oxidized) / 100;

                var species_item = {
                    name,
                    concentration,
                    percent_oxidized,
                    fraction_reduced
                };

                species_array.push(JSON.stringify(species_item));
            };
        };
        
        // Return the species parameters
        return species_array;
    }

    // Run the simulation
    function runSimulation() {
        if(!state.http.simulationRunning) {
            // Collect all the simulation parameters
            var simulation_params = collectSimulationParams();
            var species_params = collectSpeciesParams();

            // Concatenate into a string for post request
            var http_params = "type=run&species=[" + species_params.toString() + "]&simulation_params='" + JSON.stringify(simulation_params) + "'";
            dispatch(SimulationActions.runSimulation(http_params, simulation_params.cycles, simulation_params.wave_type));
        }
    };

    // Called when the button is clicked
    const buttonClickedEvent = event => {
        event.preventDefault();
        runSimulation();
    };

    // DOM Element Initialization
    var simulate_button_view = null;
    if(!httpState.simulationRunning) {
        simulate_button_view = 
            <button className="simulate_button" onClick={buttonClickedEvent}>
                Simulate
            </button>;
    } else {
        simulate_button_view = <img className="loading_gif" src={process.env.PUBLIC_URL + '/paredoxload.gif'} alt="Loading icon" />;
    }

    return(
        <div className="simulate_button_and_loading_container">
            {simulate_button_view}
        </div>
    );
};

export default SimulateButton;