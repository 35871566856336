import React from 'react';
import Plot from 'react-plotly.js';
import './styles.css';
import {useSelector} from 'react-redux';
import { frechetDistance, shapeSimilarity } from 'curve-matcher';

function CVTPlot(props) {
    // Store Interfaces
    const state = useSelector(state => state.plotData);
    const UIstate = useSelector(state => state.ui);

    // Default layout properties
    var config = state.default_config;
    var layout = state.default_layout;
    var frequency = state.cvtPlotData.frequency;
    var freq_annotation = state.annotations.frequency_annotation;
    var freqtext='Frequency (Hz): ' + frequency.toFixed(3);
    var frechetV= null;
    var frechetI= null;
    var similarityV= null;
    var similarityI= null;
    var comparisonstring=null;

    if(!UIstate.simulationHasRan && UIstate.experimentalLoaded){
        freqtext='';
    }
    var new_layout = { ...layout,
        hovermode: 'closest',
        title: { ...layout.title, text: 'Current & Voltage vs Time' },
        xaxis: { ...layout.xaxis, title: { ...layout.xaxis.title, text: 'Time (s)' } },
        yaxis: { ...layout.yaxis, title: { ...layout.yaxis.title, text: 'Current (uA)' } },
        yaxis2: { ...layout.yaxis,
            title: { ...layout.yaxis.title, text: 'Voltage (mV)' },
            visible: 'voltage_visible',
            overlaying: 'y',
            side: 'right',
            zeroline: false
        },
        annotations: [{ ...freq_annotation, text: freqtext }],
        automargin: true,
    };
    //console.log("updated cvt plot");
    //console.log(state.cvtPlotDataLoaded);
    var vt_data={};
    var ct_data={};
    var vt_data_loaded={};
    var ct_data_loaded={};
    if(state.compare_data){
        vt_data_loaded=state.cvtPlotDataLoaded.vt_data;
        ct_data_loaded=state.cvtPlotDataLoaded.ct_data;
    }
    if(!UIstate.simulationHasRan){
        vt_data.visible= false;
        ct_data.visible= false;
    }
    else{
        vt_data= state.cvtPlotData.vt_data;
        ct_data= state.cvtPlotData.ct_data;
        vt_data.visible= true;
        ct_data.visible= true;
    }
    if(UIstate.simulationHasRan && state.compare_data){
        var frechetSimV= [];
        var frechetLoadV= [];
        var frechetSimI= [];
        var frechetLoadI= [];
        vt_data.x.forEach(function (item, index){
            frechetSimV.push({x: vt_data.x[index], y: vt_data.y[index]});
        });
        vt_data_loaded.x.forEach(function (item, index){
            frechetLoadV.push({x: vt_data_loaded.x[index], y: vt_data_loaded.y[index]});
        });
        ct_data.x.forEach(function (item, index){
            frechetSimI.push({x: ct_data.x[index], y: ct_data.y[index]});
        });
        ct_data_loaded.x.forEach(function (item, index){
            frechetLoadI.push({x: ct_data_loaded.x[index], y: ct_data_loaded.y[index]});
        });            
        frechetV= frechetDistance(frechetSimV, frechetLoadV);
        frechetI= frechetDistance(frechetSimI, frechetLoadI);
        similarityV= shapeSimilarity(frechetSimV, frechetLoadV);
        similarityI= shapeSimilarity(frechetSimI, frechetLoadI);
        comparisonstring= "Frechet Distances- V:"+Math.floor(frechetV *1000)/1000 + ", I:" + Math.floor(frechetI *1000)/1000 +". Similarities- V:"+ Math.floor(similarityV *1000)/1000 +", I:" + Math.floor(similarityI*1000)/1000 ;
    }
    return(
        <div className="plot_container_pane">
            <Plot className="plot"
                id="cvt_plot"
                name="cvt_plot"
                data = {[
                    vt_data,
                    ct_data,
                    vt_data_loaded,
                    ct_data_loaded
                ]}
                layout = {new_layout}
                config = {config}
                onUpdate = {props.update}
            />
            {comparisonstring}
        </div>
    );
};

export default CVTPlot;