import React from 'react';
import './styles.css';
import {useSelector} from 'react-redux';

function ViewModuleExperimental() {
    // Store Interfaces
    const state = useSelector(state => state);
    const loadedState = useSelector(state => state.plotData);

    // DOM Element Initialization
    var experimental_data = null;
    if(state.ui.loadedSimulation && state.ui.experimentalLoaded) {
        //console.log(loadedState);
        experimental_data = loadedState.experimental_state.experimental_data;
        //loadedState.speciesList[loadedState.speciesTabSelected];
        //console.log(species_item);

    } else {
        experimental_data = {
            header: "N/A",
            voltage_init: "N/A",
            voltage_max: "N/A",
            voltage_min: "N/A",
            init_PN: "N/A",
            scan_rate: "N/A",
            segment: "N/A",
            voltage_sample_interval: "N/A",
            quiet_time: "N/A",
            sensitivity: "N/A",
        };
    }

    return(
        <div className="form_module" id="load_module_waveform">
            <h4>Experimental Data</h4>
            <label id="load_module_header">
                Header: <div><span>{experimental_data.header}</span></div>
            </label>
            <label>
                Init E (V): <span>{experimental_data.voltage_init}</span>
            </label>
            <label>
                High E (V): <span>{experimental_data.voltage_max}</span>
            </label>
            <label>
                Low E (V): <span>{experimental_data.voltage_min}</span>
            </label>
            <label>
                Init P/N: <span>{experimental_data.init_PN}</span>
            </label>
            <label>
                Scan Rate (V/s): <span>{experimental_data.scan_rate}</span>
            </label>
            <label>
                Segment: <span>{experimental_data.segment}</span>
            </label>
            <label>
                Sample Interval (V): <span>{experimental_data.voltage_sample_interval}</span>
            </label>
            <label>
                Quiet Time (sec): <span>{experimental_data.quiet_time}</span>
            </label>
            <label>
                Sensitivity: <span>{experimental_data.sensitivity}</span>
            </label>
        </div>
    );
};

export default ViewModuleExperimental;