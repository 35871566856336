import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import DatabaseActions from '../../actions/DatabaseActions';
import FormActions from '../../actions/FormActions';
import UIActions from './../../actions/UIActions';


function LoginBar() {
    // Store Interfaces
    const state = useSelector(state => state.http);
    const UIstate = useSelector(state=> state.ui);
    const formState = useSelector(state => state.form);

    const dispatch = useDispatch();

    // Called when the login button is clicked - authenticate user
    function loginEvent() {
        dispatch(DatabaseActions.authenticateUser(state.session_username, state.session_password, UIstate.pageState));

    };

    // Called when the logout button is clicked - deauthenticate user
    function logoutEvent() {
        dispatch(DatabaseActions.logoutUser(state.session_username));
        dispatch(FormActions.optionSelected('species', 0));
        if(UIstate.pageState==="modify_species"){
            dispatch(UIActions.pageStateChanged("login"));
        }
        //NEED Section which resets the selected values within the species List.
        //console.log(formState.species.speciesList);
    };
    // Called when a text field is changed
    const textFieldChangedEvent = event => {
        dispatch(FormActions.textFieldChanged("session", event.target.name, event.target.value));
    };
    //called when the show password buttin is changed. Searches for the element with id passwordInput, and then, based on state.sessionHidePassword, changes the input type.



    // DOM Element Initialization
    // Text shown when username/password sent is invalid
    var invalidLoginMessage = null;
    if(state.sessionInvalid) {
        invalidLoginMessage = <h4 className="invalid_message">Invalid username/password!</h4>;
    }
    var usernameJSX= <label>Username: <input type="text" className="input_username" name="username" value={state.session_username} onChange={textFieldChangedEvent} /></label>;
    var passwordJSX= <label>Password: <input type="password" className="input_password" id="passwordInput" name="password" value={state.session_password} onChange={textFieldChangedEvent} /></label>;
    var loginButtonJSX= <button className="login_button" onClick={loginEvent}>Login</button>;
    var loggedIn= state.sessionLoggedIn;
    var logoutButtonJSX="";
    var LoggedInMessage="";
    if(loggedIn){
        usernameJSX="";
        passwordJSX="";
        loginButtonJSX="";
        invalidLoginMessage="";
        LoggedInMessage="Logged in as: "+state.session_username;
        logoutButtonJSX= <button className="logout_button" onClick={logoutEvent}>Logout</button>;
    }

    return(
        <div className="login_bar" >
            {LoggedInMessage}
            {invalidLoginMessage}
            {usernameJSX}
            {loginButtonJSX}
            {passwordJSX}         
            {logoutButtonJSX}
        </div>
    );
};

export default LoginBar;